import React from "react";
import { NavLink } from "react-router-dom";
import JoinFreeBtn from "../button/PrimaryButton";
import { motion } from "framer-motion";

const ExploreCourse = (props) => {

  return (
    <>
      <div className="container mx-auto  pb-10">
        <div
          className={`grid grid-cols-1 sm:grid-cols-${props.col1} md:grid-cols-${props.col2} lg:grid-cols-${props.col3} gap-5 py-5 max-sm:justify-items-center max-sm:grid-cols-1 max-sm:px-4 `}
        >
          <Card data={props.courseData} />
        </div>
      </div>
    </>
  );
};



export default ExploreCourse;

const Card = (props) => {
  const defaultBanner = "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
  return (
    <>
    
      {props?.data?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div>
              <motion.div
                initial={{ opacity: 1, scale: 1 }}
                whileHover={{ opacity: 1, scale: 1.05 }}
                transition={{ duration: 0.5 }}
                className="mt-4 max-w-[380px] rounded-lg bg-white shadow-md overflow-hidden relative cursor-pointer h-[22rem]"
              >
                <img
                  className="w-full h-40 rounded-t-lg object-cover"
                  src={item?.banner ? item?.banner : defaultBanner}
                  alt="card-image"
                />

                <div className="p-4 md:p-6">
                  <h2 className="font-semibold text-lg md:text-xl truncate pr-4 text-gray-900">
                    {item?.title?.length > 40 ? `${item?.title?.slice(0, 40)}...` : item?.title}
                  </h2>
                  <p className="text-sm md:text-base pt-2 md:pt-4 truncate pr-4 text-gray-600 max-h-[5.5rem] overflow-hidden">
                    {item?.about}
                  </p>
                  <div className="flex items-center pt-4 md:pt-6">
                    <span className="text-sm md:text-base font-semibold">{item?.rating}</span>
                    <span className="px-2 text-sm md:text-base">*</span>
                    <span className="text-gray-600 text-sm md:text-base">(17k views)</span>
                  </div>
                  <div className="absolute bottom-4 right-4">
                    <NavLink onClick={() => localStorage.setItem("courseId", item?.id)} to={`/courses/${item?.id}/${item?.title}`} className="text-blue-500">
                      Learn more <span>&raquo;</span>
                    </NavLink>
                  </div>
                </div>

              </motion.div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
