import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASEURL;

const apiClient = axios.create({
  baseURL: `${BASE_URL}`,
});
const apiClientRefresh = () => {
  const token = localStorage.getItem("refreshtoken");

  // Check if the token is expired
  if (token && isTokenExpired(token)) {
    logout();
    return;
  }
  refreshToken();
};

export default apiClientRefresh;

// Utility function to check if token is expired
const isTokenExpired = (token) => {
  const expiry = JSON.parse(atob(token.split(".")[1])).exp; // Decode JWT
  return Date.now() >= expiry * 1000; // Check if expired
};

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshtoken");

  if (!refreshToken) {
    // If no refresh token, log out
    logout();
    return;
  }

  try {
    const response = await apiClient.post("user/refresh-token", {
      refresh: refreshToken,
    });
    const { access_token } = response.data;
    // Store new token
    localStorage.setItem("userAuth", access_token);
  } catch (error) {
    // Handle refresh token error (e.g., token expired)
    logout();
  }
};

const logout = () => {
  localStorage.removeItem("userAuth");
  localStorage.removeItem("refreshtoken");
  // Redirect to login or show a message
};
