import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/AuthSlice";
import linkedinLoginSlice from "./features/linkedinLoginSlice";
import adminDashboardSlice from "./features/adminDashboardSlice";
import studentSlice from './studentSlice'

export default configureStore({
  reducer: {
    auth: authSlice,
    linkedinLogin: linkedinLoginSlice,
    adminDashboard: adminDashboardSlice,
    student: studentSlice
  },
});
