import React from "react";

const ProgressBar = ({
  bgcolor,
  progress,
  height,
  width,
  text,
  value,
  textColor,
}) => {
  const Parentdiv = {
    height: height,
    width: width,
    backgroundColor: "whitesmoke",
    borderRadius: 40,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: progress === 100 ? "#4BB543" : bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 4,
    color: "black",
    fontWeight: 900,
  };

  return (
    <div className="">
      <div className="flex justify-between py-1">
        <span className="text-[#1E1E1E] text-opacity-60 font-medium text-xs">
          {text}
        </span>
        <span className="text-semibold  text-sm text-[#121212]">
          {value ? `${value}%` : ""}
        </span>
      </div>
      <div style={{ ...Parentdiv, marginBottom: "15px" }}>
        <div style={Childdiv}>
          <span style={progresstext}></span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
