import React from "react";

const SelectedCourseCard = () => {
  const cardTitle = [
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
    {
      title: "Planning UX research studies",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      format: "MP3",
    },
  ];

  return (

    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {cardTitle.length > 0 &&
          cardTitle.map((item, ind) => {

            return (
              <div
                key={ind}
                className="border border-[#E1E1E1] rounded-md flex flex-col p-4 items-start shadow-md"
              >
                <p className="text-base leading-4 font-Roboto font-semibold text-[#1E1E1E] text-opacity-95">
                  {item.title}
                </p>
                <p className="text-[10px] font-Roboto text-[#696969] pt-2 pb-4">
                  {item.para}
                </p>
                <p className="font-medium font-Roboto text-xs text-[#000000] text-opacity-70">
                  Format: {item.format}
                </p>
                <div className="mt-6 mb-2">
                  <button className="flex px-2 items-center justify-center text-center gap-2 bg-[#0966ED] w-full h-[37px] rounded-md font-Roboto text-sm text-white">
                    Download
                    <img
                      className="w-[14px] h-[14px]"
                      src={
                        process.env.PUBLIC_URL + "/images/student/downArrow.png"
                      }
                      alt="Download"
                    />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectedCourseCard;
