import React, { useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { resetPasswordApi } from '../../../store/authApi';
import { toast } from 'react-toastify';

export const GenerateNewPassword = () => {
  const [formData, setFormData] = useState({ newPassword: '', confirmPassword: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const { uid, token } = useParams();
  // const uid = searchParams.get('uid'); // Get 'uid' from URL
  // const token = searchParams.get('token'); // Get 'token' from URL

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'confirmPassword') {
      if (value !== formData.newPassword) {
        setError('Passwords do not match.');
        setIsButtonDisabled(true);
      } else if (formData.newPassword.length < 8) {
        setError('Password must be at least 8 characters long.');
        setIsButtonDisabled(true);
      } else {
        setError('');
        setIsButtonDisabled(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsSubmitting(true);

    try {
     const response = await resetPasswordApi({
        new_password: formData.newPassword,
        confirm_password: formData.confirmPassword,
        token: token,
        uidb64: uid,
      });
      console.log(response)
      toast.success('Password reset successfully!');
      setSuccessMessage('Password reset successfully!');
    } catch (err) {
        console.log(err)
      setError(err.response?.data?.message || 'An error occurred while resetting the password.');
      toast.error("An error occurred while resetting the password.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-blue-100 to-gray-100">
      <div className="bg-white shadow-2xl rounded-2xl flex overflow-hidden w-[850px] transform hover:scale-105 transition duration-300">
        <div className="bg-gradient-to-br from-[#39b8d9] to-[#80c0d0] flex flex-col justify-center items-center w-1/2 p-8 text-white">
          <div className="w-24 h-24 bg-white rounded-full flex justify-center items-center shadow-lg">
            <svg
              className="w-12 h-12 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 11c0-1.657-2-3-2-3m4 3c0-1.657 2-3 2-3m-6-4a4 4 0 018 0c0 2.21-2.79 4-4 4m-4-4c0 2.21 2.79 4 4 4m0 8v2m0-2a4 4 0 01-4-4h8a4 4 0 01-4 4m0 2h.01"
              />
            </svg>
          </div>
          <p className="mt-6 text-center text-lg font-medium leading-relaxed">
            Keep your account secure by updating your password regularly.
          </p>
        </div>

        <div className="w-1/2 p-10">
          <h2 className="text-3xl font-extrabold mb-6 text-gray-800">Enter New Password</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label htmlFor="new-password" className="block text-sm font-semibold mb-2 text-gray-700">
                New Password
              </label>
              <input
                id="new-password"
                name="newPassword"
                type="password"
                className="w-full border border-gray-300 rounded-lg px-4 py-3 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                placeholder="Enter new password"
                value={formData.newPassword}
                onChange={handleChange}
              />
            </div>

            <div className="mb-6">
              <label htmlFor="confirm-password" className="block text-sm font-semibold mb-2 text-gray-700">
                Confirm Password
              </label>
              <input
                id="confirm-password"
                name="confirmPassword"
                type="password"
                className="w-full border border-gray-300 rounded-lg px-4 py-3 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>

            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            {successMessage && <p className="text-green-500 text-sm mb-4">{successMessage}</p>}

            <button
              type="submit"
              className={`bg-gradient-to-r from-blue-500 to-blue-600 text-white w-full py-3 rounded-lg font-semibold shadow-lg transition duration-300 ${
                isButtonDisabled || isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-blue-700'
              }`}
              disabled={isButtonDisabled || isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'UPDATE PASSWORD'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
