import React, { useEffect, useRef, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { FaChevronLeft } from "react-icons/fa";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import "../../assests/css/navigation.css";
import {
  getUserDetailsAsync,
  logoutStudentAsync,
} from "../../store/features/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { createReport } from "../../store/authApi";
import { toast } from "react-toastify";

const AppHeader = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [showProfile, setProfile] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.loginUserDetails);
  // const studentData = useSelector((state)=> state?.student?.studentDetails);
  // console.log("this is userDetails=>",userDetails)
  const baseUrl = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type: "issue", // Default value for the select field
    created_for: localStorage.getItem("userId"),
    images: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e) => {
    setFormData({ ...formData, images: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const formValue = new FormData();
    // formValue.append("title", formData.title);
    // formValue.append("description", formData.description);
    // formValue.append("type", formData.type);
    // formValue.append("created_for", formData.created_for);
    // formValue.append("images", formData.images);
    // createReport(formValue).then((res) => {
    //   if (res) {
    //     toast.success("Your report sent successfully!");
    //     setFormData({
    //       title: "",
    //       description: "",
    //       type: "issue", // Default value for the select field
    //       created_for: localStorage.getItem("userId"),
    //       images: null,
    //     });
    //   }
    // });
  };

  const handleClick = () => {
    props.setShowSidebar((data) => !data);
  };
  const profileMenu = [
    { ind: 1, name: "Profile", to: "/student/account-profile" },
    { ind: 2, name: "My Courses", to: "/student/MyLearning" },
    { ind: 3, name: "My Purchases", to: "/student/dashboard" },
    { ind: 4, name: "Log Out", to: "/" },
  ];

  const handleShowProfile = () => {
    setProfile((data) => !data);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    dispatch(getUserDetailsAsync(userId));
  }, []);

  const handleStudentLogin = (event) => {
    event.preventDefault();
    const eventValue = event.target.textContent;
    switch (eventValue) {
      case "Log Out":
        dispatch(logoutStudentAsync(navigate));
    }
  };
  return (
    <div className="grid grid-flow-row px-6">
      <div className="flex items-center justify-between h-[100px]">
        <div className="flex items-center gap-10">
          <div onClick={handleClick}>
            {props.showSidebar ? (
              <img
                className="w-[37px] h-[27px] cursor-pointer"
                src={process.env.PUBLIC_URL + "/images/admin/hamberger.png"}
                alt="no img"
              />
            ) : (
              <div className="bg-white cursor-pointer border-2 rounded-full text-xl border-gray-300 px-2 py-2">
                <FaChevronLeft className="text-blue-500" />
              </div>
            )}
          </div>
          <div>
            <div onClick={() => (window.location.href = "/")}>
              <img
                src={process.env.PUBLIC_URL + "/images/admin/Logo.png"}
                alt="no img"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-6 ">
          <div className="flex items-center gap-6 max-md:hidden md:invisible lg:visible">
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="bg-gray-200 rounded pl-9 pr-1 outline-none w-96 h-10 "
              />

              <div className="absolute  top-3 left-4">
                <IoSearchOutline />
              </div>
            </div>
            {/* <div className="bg-[#F5F7FA] px-2 py-2 rounded-lg">
              <img
                src={process.env.PUBLIC_URL + "/images/admin/Icon.png"}
                alt="no img"
              />
            </div> */}
            <div className="bg-[#F5F7FA] px-2 py-2 rounded-lg ">
              <img
                src={process.env.PUBLIC_URL + "/images/admin/Share.png"}
                alt="no img"
              />
            </div>
            <button
              onClick={() => setShowModel(!showModel)}
              className="flex items-center  gap-1 bg-[#FFEDEA] px-2 py-1 rounded-md  animate-bounce border-red-800 border-2 "
            >
              <img
                src={process.env.PUBLIC_URL + "/images/student/i.png"}
                alt="no img"
              />
              <div className="text-[#FF3A29] text-sm font-Roboto font-medium ">
                SOS
              </div>
            </button>
            <button className="bg-blue-500 flex items-center gap-2 px-2 py-1 rounded">
              <img
                src={process.env.PUBLIC_URL + "/images/admin/lmsvector.png"}
                alt="no img"
              />
              <span className="text-white font-Roboto text-sm font-medium ">
                LMS
              </span>
            </button>
          </div>

          <div
            className="relative cursor-pointer"
            onClick={() => handleShowProfile()}
          >
            <div className="flex flex-col items-center relative w-[6rem]">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-300 overflow-hidden">
                <img
                  // src={`${baseUrl}${studentData?.profile?.image}`}
                  src={`${baseUrl}${userDetails?.profile?.image}`}
                  alt="Avatar"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute top-2 right-0 ">
                {showProfile ? (
                  <IoIosArrowUp className="text-2xl text-black transition-transform duration-200" />
                ) : (
                  <IoIosArrowDown className="text-2xl text-black transition-transform duration-200" />
                )}
              </div>
              <span className="text-sm font-medium font-Roboto text-[#1E1E1E] text-opacity-90 pt-1">
                {userDetails?.name}
              </span>
              <span className="text-xs font-normal font-Roboto text-[#1E1E1E] text-opacity-60">
                {userDetails?.user_type}
              </span>
            </div>

            {showProfile && (
              <div
                onClick={handleStudentLogin}
                className="absolute top-[4rem] right-0 bg-white w-[150px] shadow-lg rounded-md py-2 z-10 transition-all duration-300 ease-in-out transform scale-100"
              >
                {profileMenu?.map((item, ind) => (
                  <div
                    key={ind}
                    className="flex items-center justify-center flex-col"
                  >
                    <span
                      onClick={() => {
                        navigate(item?.to);
                      }}
                      className="w-full leading-4 cursor-pointer hover:bg-[#F1F4F9] text-black py-2 text-center rounded-md transition-colors duration-200"
                    >
                      {item?.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* <div
            className="relative cursor-pointer"
            onClick={() => handleShowProfile()}
          >
            <div className="flex flex-col items-center relative">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-300">
                <img
                  src={`${baseUrl}${userDetails?.image}`}
                  alt="Avatar"
                  className="w-full h-full object-contain rounded-full"
                />
              </div>
              <div className="absolute top-4 right-0">
                {showProfile ? (
                  <IoIosArrowUp className="text-2xl text-black" />
                ) : (
                  <IoIosArrowDown className="text-2xl text-black" />
                )}
              </div>
              <span className="text-sm font-medium font-Roboto text-[#1E1E1E] text-opacity-90 pt-1">
                {userDetails?.name}
              </span>
              <span className="text-xs font-normal font-Roboto text-[#1E1E1E] text-opacity-60">
                {userDetails?.user_type}
              </span>
            </div>
            {showProfile && (
              <div
                onClick={handleStudentLogin}
                className="absolute top-10 shadow-md z-10  bg-white w-[150px] right-0  py-2 rounded-md"
              >
                {profileMenu?.map((item, ind) => {
                  return (
                    <div
                      key={ind}
                      className="flex items-center justify-center flex-col px-2"
                    >
                      <span
                        onClick={() => {
                          navigate(item?.to);
                        }}
                        className="leading-4 cursor-pointer hover:bg-[#F1F4F9]  hover:w-full hover:text-center py-2 hover:text-black hover:rounded-md"
                      >
                        {item?.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div> */}
        </div>
        {showModel && (
          <div className="w-full h-full bg-gray-900 bg-opacity-50 fixed top-0 left-0 flex justify-center items-center z-50">
            <div className="w-[50vw] mx-auto bg-white p-6 rounded-md shadow-md mt-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex justify-end">
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded-md"
                  onClick={() => setShowModel(!showModel)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <h2 className="text-2xl font-bold mb-4 text-gray-800">
                Create a Report
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                {/* Title Field */}
                <div>
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Title <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={formData.title}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Title of the report"
                    required
                  />
                </div>

                {/* Description Field */}
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Description of the report"
                    rows="4"
                    required
                  />
                </div>

                {/* Type of Report */}
                <div>
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Type <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="type"
                    id="type"
                    value={formData.type}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="issue">Issue</option>
                    <option value="feedback">Feedback</option>
                    <option value="bug">Bug</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                {/* Created for (User ID) */}
                <div>
                  <label
                    htmlFor="created_for"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Created For (User ID){" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    disabled
                    type="text"
                    name="created_for"
                    id="created_for"
                    value={formData.created_for}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="User ID of the person for whom the report is created"
                    required
                  />
                </div>

                {/* Image Upload */}
                <div>
                  <label
                    htmlFor="images"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload Image (optional)
                  </label>
                  <input
                    type="file"
                    name="images"
                    id="images"
                    onChange={handleFileChange}
                    className="mt-1 block w-full text-sm text-gray-700"
                  />
                  <span className="text-sm text-gray-500">No file chosen</span>
                </div>

                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Submit Report
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
