import React, { useState, useEffect } from "react";
import { RiArrowDropDownFill } from "react-icons/ri";
import { createUserRequest, getCourseListDashboard } from "../../store/authApi";
import { typeofCountryAsync } from "../../store/features/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import expert from '../../assests/images/expertHelp.jpg'
import getexpert from '../../assests/images/getExpertHelp.jpg'
// import { backgroundImage } from "html2canvas/dist/types/css/property-descriptors/background-image";
const categories = [
  "Programming and Software Development",
  "Internet of Things (IoT)",
  "Data Science and Analytics",
  "Web Development",
  "Others...",
];

export const ExpertLeft = () => {
  const [selectedCode, setSelectedCode] = useState("+91");
  const [countries, setCountries] = useState([]);
  const { countrylist } = useSelector((state) => state.auth);
  const [courseData, setCourseData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isOthersSelected, setIsOthersSelected] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
 
  const dispatch = useDispatch();
  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const sortedCountries = data
          .map((country) => ({
            name: country.name.common,
            code: country.idd?.root
              ? `${country.idd.root}${country.idd.suffixes?.[0] || ""}`
              : "",
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCountries(sortedCountries);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  useEffect(() => {
    getCourseListDashboard().then((res) => setCourseData(res.results));
  }, []);

  useEffect(() => {
    dispatch(typeofCountryAsync());
  }, [dispatch]);


  const formik = useFormik({
    initialValues: {
      describesYouBest: "",
      selectCourse: "",
      subject: "",
      email: "",
      country: "India",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      describesYouBest: Yup.string().required("Please select an option"),
      selectCourse: Yup.string().required("Please select a course"),
      subject: Yup.string().required("Subject is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Phone number is not valid")
        .min(10, "Phone number should be at least 10 digits")
        .required("Phone number is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("type", values.describesYouBest);
      formData.append("subject", values.subject);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone);
      formData.append("country", values.country);
      formData.append("message", values.message);
      formData.append("course", values.selectCourse);

      createUserRequest(formData)
        .then((res) => {
          if (res) {
            toast.success("Form submitted successfully!");
            resetForm();
          }
        })
        .catch(() => {
          toast.error("Failed to submit the form. Please try again.");
          resetForm();
        });
    },
  });


  const handleCountryChange = (value) => {
    const selectedCountry = countries.find((country) => country.name === value);
    if (selectedCountry) {
      setSelectedCode(selectedCountry.code || "+91"); // Update country code
      formik.setFieldValue("country", selectedCountry.name); // Set the country in formik
    }
  };

  // for input box
  const handleCourseSelect = (title)=>{
    setIsOthersSelected(false);
    formik.setFieldValue("selectCourse", title)
    setSelectedCourse(title);
  }

  return (
    <>
 

  

      <div className="expertLeft">
        <h1 className="text-black text-[2rem] font-bold mb-1">
          Get Expert Help
        </h1>
        {/* <p className="text-black pb-2 font-sans font-medium">
          If you have any questions or requests for any course, please fill in
          the form below and we will get back to you as soon as possible.

        </p> */}
      </div>
      {/* categories */}
      <div>
      <div className="flex flex-wrap gap-4 py-2">
        {courseData?.slice(0, showAll ? courseData.length : 4)?.map((cat, index) => (
          <button
          onClick={() => handleCourseSelect(cat?.title)}
            key={index}
            className={`px-2 py-1 text-md rounded-2xl focus:outline-none border border-gray-700  focus:ring-2 
              ${selectedCourse === cat?.title ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'}`}
          >
            {cat?.title}
          </button>
        ))}
          <button
          onClick={() => {
            setIsOthersSelected(true);
            formik.setFieldValue("selectCourse", ""); 
          }}
           className='px-2 py-1 text-sm rounded-2xl focus:outline-none border border-gray-700 hover:bg-blue-500 hover:text-white focus:ring-2 '
        >
          Others
        </button>
        {courseData?.length > 4 && (
      
      <button
         className='px-2 py-1 text-sm rounded-2xl focus:outline-none border border-gray-700 hover:bg-blue-500 hover:text-white focus:ring-2 '
        onClick={() => setShowAll(!showAll)}
      >
        {showAll ? "Less" : "More"}
      </button>
  
  )}
      </div>
    
    </div>

      <form
        className="bg-white px-1 pt-6 pb-1 mb-1"
        onSubmit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          <div className="relative mb-4">
            <select
              name="describesYouBest"
              value={formik.values.describesYouBest}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`appearance-none w-full bg-white border-2 border-gray-300 rounded-md pl-2  py-2 px-3 focus:outline-none focus:border-blue-500 ${formik.touched.describesYouBest &&
                  formik.errors.describesYouBest
                  ? "border-red-500"
                  : ""
                }`}
            >
              <option value="">What describes you best</option>
              <option value="Individuals">Individuals</option>
              <option value="Corporates">Corporates</option>
              <option value="University">University</option>
            </select>
          </div>

{/* select course */}
          <div className="relative mb-4">
            <input
              name="selectCourse"
              value={formik.values.selectCourse}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`appearance-none w-full bg-white border-2 placeholder:text-black text-black border-gray-300 rounded-md pl-2 pr-16 py-2 px-3 focus:outline-none focus:border-blue-500 ${formik.touched.selectCourse && formik.errors.selectCourse
                  ? "border-red-500"
                  : ""
                }`}
                placeholder= {isOthersSelected? "Enter your course": "Selected Course"}
                readOnly={!isOthersSelected}
            />
            
         
          </div>


          {/* <div className="relative mb-4">
            <select
              name="selectCourse"
              value={formik.values.selectCourse}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`appearance-none w-full bg-white border-2 border-gray-300 rounded-md pl-2 pr-16 py-2 px-3 focus:outline-none focus:border-blue-500 ${formik.touched.selectCourse && formik.errors.selectCourse
                  ? "border-red-500"
                  : ""
                }`}
            >
              <option value="">Select Course</option>
              {courseData?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.title}
                </option>
              ))}
            </select>
          </div> */}

          <div className="mb-4">
            <input
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`appearance-none border-[2px] border-gray-300 rounded w-full py-2 px-3 placeholder:text-black text-black leading-tight focus:outline-none ${formik.touched.subject && formik.errors.subject
                  ? "border-red-500"
                  : ""
                }`}
              id="subject"
              type="text"
              placeholder="Subject"
            />
          </div>
          <div className="mb-4">
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`appearance-none border-[2px] border-gray-300 rounded w-full placeholder:text-black py-2 px-3 text-black leading-tight focus:outline-none ${formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : ""
                }`}
              id="email"
              type="email"
              placeholder="Email"
            />
          </div>
          <div className="mb-4 relative">
            <select
              name="country"
              value={formik.values.country}
              onChange={(e) => {
                handleCountryChange(e.target.value);
                formik.handleChange(e.target.value);
              }}
              onBlur={formik.handleBlur}
              className="appearance-none border-[2px] border-gray-300 rounded w-full py-2 px-3 text-black leading-tight focus:outline-none bg-white"
              id="country"
            >
              <option>Select Country</option>
              {countries?.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name} ({item.code})
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black">
              <RiArrowDropDownFill size={34} />
            </div>
          </div>
          <div className="mb-4 flex">
            <div className="flex items-center justify-center bg-gray-100 placeholder:text-black px-4 rounded-l-md border-[2px] border-gray-300 text-black">
              {selectedCode}
            </div>
            <input
              name="phone"
              value={formik.values.phone}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  if (e.target.value.length <= 10) {
                    formik.setFieldValue("phone", e.target.value);
                  }
                }
              }}
              onBlur={formik.handleBlur}
              className={`appearance-none border-[2px] border-gray-300 rounded-r-md w-full py-2 px-3 placeholder:text-black text-black leading-tight focus:outline-none ${formik.touched.phone && formik.errors.phone
                  ? "border-red-500"
                  : ""
                }`}
              id="phone"
              type="phone"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div className="mb-4">
          <textarea
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`appearance-none border-[2px] border-gray-300 rounded w-full py-2 px-3 text-black placeholder:text-black leading-tight focus:outline-none ${formik.touched.message && formik.errors.message
                ? "border-red-500"
                : ""
              }`}
            id="message"
            rows="5"
            placeholder="Your Message"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white rounded-md px-6 py-2 w-full hover:bg-blue-600"
        >
          Submit
        </button>
        <ToastContainer />
      </form>
   

    </>
  );
};
