import React from "react";

import { FcGoogle } from "react-icons/fc";

import { GoogleLogin } from "@react-oauth/google";

const GoogleLoginButton = ({ errorMessage, responseMessage }) => {
  return (
    <div>
      <div className="relative mb-4">
        <button className="bg-white absolute bottom-0   w-full border border-gray-400 font-semibold py-2 px-4 mt-7 rounded flex items-center justify-start">
          <span className="flex-shrink-0">
            <FcGoogle />
          </span>
          <span className="flex-grow text-center">Continue with Google</span>
        </button>
        <div className="flex opacity-5 items-center w-full overflow-hidden ">
          <GoogleLogin
            // className="custom-google-button"
            onSuccess={responseMessage}
            onError={errorMessage}
            buttonText="Sign in with Google"
          />
          <GoogleLogin
            // className="custom-google-button"
            onSuccess={responseMessage}
            onError={errorMessage}
            buttonText="Sign in with Google"
          />
        </div>
      </div>
    </div>
  );
};

export default GoogleLoginButton;
