import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../assests/css/navigation.css";
import Logo from "../../../assests/images/Logo.png";
import downArrow from "../../../assests/images/downArrow.png";
import search from "../../../assests/images/search.png";
import JoinFreeBtn from "../../button/PrimaryButton";
import Navigation from "./TopNavigation";
import Login from "../login/Login";
import Signup from "../signup/Signup";
import { NavLink } from "react-router-dom";
import { RoutePaths } from "../../../route/RoutePath";
import { toast } from "react-toastify";
import { FaSearch, FaUserCircle } from "react-icons/fa";
import { Sidebar } from "../../Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { logoutStudentAsync } from "../../../store/features/AuthSlice";
import {
  changeShowLogin,
  changeCredential,
} from "../../../store/features/linkedinLoginSlice";
import { FaGreaterThan } from "react-icons/fa6";
import { getCategories, getCourseListDashboard } from "../../../store/authApi";
import Header from "./Header";


const BottomNav = () => {
  const [showInstructorJoin, setShowInstructorJoin] = useState(false);
  const [forgetPassword, setForgetPassword] = useState("login");
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isCrossIcon, setIsCrossIcon] = useState(false);
  const [showExplore, setShowExplore] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  
  const { showLoginModal, signupModal } = useSelector(
    (state) => state.linkedinLogin
  );



  let hoverTimeout;

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => setIsHovered(false), 200); // Small delay to prevent quick hide
  };



  const baseUrl = process.env.REACT_APP_BASEURL;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setShowSidebar(!showSidebar);
    setIsCrossIcon(!isCrossIcon);
  };



  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const [scrolling, setScrolling] = useState(false);

  const handleLoginClick = () => {
    dispatch(changeShowLogin(true));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ---------------login with linkedin------------------
  useEffect(() => {
    const fetchAccessToken = async (code) => {
      try {
        const response = await axios.post(
          `${baseUrl}user/linkedin/user_link/`,

          {
            code: code,
          }
        );
        const accessData = response.data;

        toast.success(response?.data?.message);
        if (response?.data?.access) {
          localStorage.setItem(
            "userAuth",
            JSON.stringify(response?.data?.access)
          );
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );
        }
        if (response?.data?.refresh) {
          localStorage.setItem(
            "refreshtoken",
            JSON.stringify(response?.data?.refresh)
          );
        }

        if (accessData) {
          if (!accessData.hasOwnProperty("user_type")) {
            dispatch(changeShowLogin(true));
            dispatch(changeCredential(true));
          } else {
            dispatch(changeCredential(false));
          }

          if (accessData.user_type === "student") {
            navigate("/student/dashboard");
          } else if (accessData.user_type === "admin") {
            navigate("/admin/dashboard");
          } else if (accessData.user_type === "corporate") {
            navigate("/corporate/dashboard");
          } else if (accessData.user_type === "teacher") {
            navigate("/teacher/dashboard");
          } else {
            navigate("/");
          }
        }
        // Use the access token to fetch user data
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    const params = new URLSearchParams(location.search);
    const code = params.get("code");
   
    if (code) {
      fetchAccessToken(code);
    }
  }, [location]);

  // --------------getCourse------------------------
  useEffect(() => {
    setLoading(true);
    getCourseListDashboard()
      .then((res) => {
        if (res) {
          setCourseData(res)
          setLoading(false)
        }

      })
      .catch((error) => setLoading(false));
  }, []);

  // ---------------Get Categories------------------

  useEffect(() => {
    setLoading(true);
    getCategories()
      .then((res) => {
        if (res) {
           console.log(res.results);
          setData(res?.results);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  }, []);
  

  const handleItemClick = (id) => {
    setShowExplore(!showExplore);
    setSearchValue("");
    
  };
  const handleFreeCourseClick = () => {
    setShowExplore(false)
    setSearchValue("free");

    if (Array.isArray(courseData)) {
      const results = courseData.filter((course) =>
        course.title.toLowerCase().includes("free")
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]); // Ensure fallback if courseData is not ready
    }
  }
  
  const handleInputChange = (e) => {
    const term = e.target.value;
  setSearchValue(term);

  if (term) {
    const results = Array.isArray(courseData)
      ? courseData.filter((course) =>
          course.title.toLowerCase().includes(term.toLowerCase())
        )
      : [];
    setFilteredResults(results);
  } else {
    setFilteredResults([]);
  }
  };
  const handleItemClickCourseNavigation = (item) => {
    const query = encodeURIComponent(item.title);
    // const language = encodeURIComponent(item.language);
    const url = `/search?query=${query}`;

    navigate(url);
    setFilteredResults([]); // Clear the results after selection
  };

  const toggleExploreDropdown = () => {
    setShowExplore((prev) => !prev);
    setRotateArrow((prev) => !prev);
  };
  return (
    <React.Fragment>
      
      
      <div className=" sticky top-0 z-50 bg-white flex justify-between  items-center max-sm:p-4 sm:p-4 lg:p-0 ">
        <div className="lg:hidden items-center">
          <button
            className={`text-${scrolling ? "black" : "black"}`}
            onClick={handleClick}
          >
            {isCrossIcon === true ? (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-12 h-10"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-12 h-10"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            )}
          </button>
        </div>
     

        {!isInputVisible ? (
          <div>
            <NavLink
              to={RoutePaths.home.path}
              className="logo_with_btn lg:hidden"
            >
              <img src={Logo} alt="nav-logo" className="cursor-pointer" />
            </NavLink>
          </div>
        ) : null}

        {/* input search fields for mobiles  */}

        <div className="search-field flex justify-center items-center lg:hidden relative">
          <input
            className={`outline-none border text-xl border-black rounded-md transition-all duration-500 ease-in-out ${isInputVisible
                ? "w-[18rem] px-4 py-2 opacity-100"
                : "w-0 px-0 opacity-0"
              }`}
            type="text"
            placeholder="Search..."
            style={{ display: isInputVisible ? "block" : "none" }}
          />
          <span
            className="magnifying-glass-container flex justify-center items-center bg-gray-100 rounded-full p-2 cursor-pointer hover:bg-gray-200 transition-colors duration-300 absolute right-0"
            onClick={toggleInputVisibility}
          >
            <FaSearch className="w-6 h-6 text-black hover:text-gray-500 transition-colors duration-300" />
          </span>
        </div>
      </div>

      {/* Sidebar */}
      <div className=" sticky top-0 z-50">
        {showSidebar && (
          <LeftTransition>
            <Sidebar
              isCrossIcon={isCrossIcon}
              setIsCrossIcon={setIsCrossIcon}
            />
          </LeftTransition>
        )}
      </div>

      {/* Main Content */}
      

      {/* Login Modal part*/}
      {showLoginModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50">
          <Login
            // setShowSignUpPage={setShowSignUpPage}
            setForgetPassword={setForgetPassword}
            forgetPassword={forgetPassword}
          />
        </div>
      )}

      {/* Sign Up Page */}
      {signupModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50">
          <Signup
            // setShowSignUpPage={setShowSignUpPage}
            // showSignUpPage={showSignUpPage}
            showInstructorJoin={showInstructorJoin}
            setShowInstructorJoin={setShowInstructorJoin}
          />
        </div>
      )}
      {windowWidth >= 1024 && ( <Header/>)}
    </React.Fragment>
  );
};

export default BottomNav;

export const LeftTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ x: -10, opacity: 0 }} // initial position and opacity
      animate={{ x: 0, opacity: 1 }} // animate to this position and opacity
      exit={{ x: -50, opacity: 0 }} // animate out to this position and opacity
      transition={{ type: "tween", duration: 0.05 }} // animation transition type and duration
    >
      {children}
    </motion.div>
  );
};
