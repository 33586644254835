import React, { useEffect, useState } from "react";
import CardTwo from "../card/CardTwo";
import { useNavigate } from "react-router-dom";
import { getCategories, getCourseListDashboard } from "../../store/authApi";

const TeacherCoolCourses = () => {
  const navigate = useNavigate();


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  
  // useEffect(() => {
  //   setLoading(true);
  //   getCourseListDashboard()
  //     .then((res) => {
  //       if (res) {
  //         setCourseData(res.results);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => setLoading(false));
  // }, []);

  useEffect(() => {
    getCategories().then((res) => setData(res?.results));
  }, []);
  console.log("first data,", data)

  return (
    <>
      <div
        // onClick={() => navigate("/courses")}
        className="container px-[7.25rem] mx-auto py-10 max-sm:px-4"
      >
        <div>
          <h2 className="font-bold text-5xl max-sm:text-3xl ">
            Explore TeacherCool <span className="text-blue-500">Courses</span>
          </h2>
          <p className="text-sm pt-1 text-gray-600 pr-1 ">
            Explore TeacherCool courses and discover a world of knowledge
            designed to elevate your skills and career.
          </p>
          <div>
            <CardTwo data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherCoolCourses;
