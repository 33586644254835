import React, { useState, useEffect, useRef } from "react";
import Card from "../card/Card";
import { NavLink } from "react-router-dom";
import { getCourseListDashboard } from "../../store/authApi";
import ClipLoader from "react-spinners/ClipLoader";

const CourseToday = () => {
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getCourseListDashboard()
      .then((res) => {
        if (res) {
          setCourseData(res.results);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  }, []);

  const scrollRef = useRef(null);

  // Scroll Left Function
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  // Scroll Right Function
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  return (
    <>
      <div className="container px-[7.25rem] max-sm:px-4 mx-auto pt-5 pb-10 w-full ">
        <div>
          <div className="w-full flex gap-2 justify-center  items-center md:justify-between flex-wrap">
            <h2 className="font-bold text-4xl max-sm:text-2xl max-sm:px-3 py-2">
              Get Started with your Courses today!
            </h2>
            <NavLink
              to="/forIndividual"
              className="font-medium text-base w-[10rem] h-8 flex justify-center items-center text-gray-900 shadow-md"
            >
              Explore all courses <span>&raquo;</span>
            </NavLink>
          </div>
          <div className="flex mt-4 justify-between items-center">
            <p className="text-base pt-1 text-gray-500 max-sm:font-medium font-semibold pr-1 text-wrap max-sm:px-3">
              Get started with your courses today and unlock new opportunities
              for learning and growth.
            </p>
          </div>
        </div>
        
        {/* scroll buttons  */}
        <div className="relative w-full">
        <button
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 z-40 bg-white opacity-90  transform -translate-y-1/2 text-black p-2 rounded-full shadow-md hidden md:block"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {/* Scrollable Card List */}
        <div
          ref={scrollRef}
          className="flex overflow-x-auto gap-3 py-5 max-sm:justify-items-center max-sm:px-4 scroll-smooth hide-scrollbar"
        >
          {loading ? (
            <div className="h-[17rem] w-full flex flex-col items-center justify-center">
              <ClipLoader color="#007bff" loading={loading} size={50} />
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-gray-700 mb-2 mt-3">
                Loading...
              </h2>
              <p className="text-base sm:text-lg lg:text-xl text-gray-500">
                Please wait while we fetch the data.
              </p>
            </div>
          ) : courseData?.length > 0 ? (
            <Card data={courseData.slice(0, 6)} />
          ) : (
            <div className="h-[17rem] w-full flex flex-col items-center justify-center">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl mt-3 font-semibold text-gray-700 mb-2">
                Data not found!
              </h2>
              <p className="text-base sm:text-lg lg:text-xl text-gray-500 mb-6">
                Sorry, we couldn’t find the data you’re looking for. Please try
                again later.
              </p>
            </div>
          )}
        </div>

        {/* Right Scroll Button */}

        <button
          onClick={scrollRight}
          className="absolute right-0 top-1/2 opacity-90 bg-white transform -translate-y-1/2  text-black p-2 rounded-full shadow-md hidden md:block"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
        </div>
      </div>
    </>
  );
};

export default CourseToday;
