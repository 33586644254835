import React, { useState } from "react";

const SecondaryButton = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
      <div className="flex flex-wrap gap-4 items-center pt-8 pb-3 max-sm:px-3">
        <button
          onClick={() => {
            props.setCatId("");
            handleButtonClick(0);
          }}
          className={`border border-gray-300 px-4 py-2 rounded-md 
              ${
                activeIndex === 0
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700"
              }
               // Add margin-bottom to the last button
            `}
        >
          All Popular
        </button>
        {props.data?.map((item, index) => (
          <button
            onClick={() => {
              props.setCatId(item?.id);
              handleButtonClick(index + 1);
            }}
            key={index}
            className={`border border-gray-300 px-4 py-2 rounded-md 
              ${
                index + 1 === activeIndex
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700"
              }
               // Add margin-bottom to the last button
            `}
          >
            {item?.cat_name}
          </button>
        ))}
        {/* <img
          src={process.env.PUBLIC_URL + "/images/arrow-right.png"}
          alt="arrow"
          className="h-10"
        /> */}
      </div>
    </>
  );
};

export default SecondaryButton;
