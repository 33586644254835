import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import CardTwo from '../../components/card/CardTwo'
const StudentCatButtons = ({ data }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <>
      <div className="mx-10 my-8 space-y-4">
        <CardTwo data={data} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4" />
      </div>

      {/* <div className="px-4 md:px-8 lg:px-16 py-8">
        <h2 className="text-2xl font-semibold mb-6">Categories</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {data?.map((category, index) => (
            <bu              key={index}
              className="text-white bg-blue-900 hover:bg-blue-700 font-semibold py-2 px-4 rounded-lg w-full text-left"
              onClick={() => {setSelectedCategory(category); 
                window.scrollTo({
                  top: 2000, 
                  behavior: "smooth",
                });
              }
              }
            >
              {category.cat_name}
            </bu>
          ))}
        </div>
      </div> */}

    </>
  );
};



export default StudentCatButtons;

