import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASEURL;

export function googleLogin(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/auth/google/`, data)
      .then(function (response) {
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          localStorage.setItem(
            "userAuth",
            JSON.stringify(response?.data?.access)
          );
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );

          localStorage.setItem(
            "refreshtoken",
            JSON.stringify(response?.data?.refresh)
          );
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function linkedinLoginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/get_url/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function userLogin(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/login`, data)
      .then(function (response) {
        if (response.data.success) {
          resolve(response?.data);
          // console.log("Success", response?.data?.message)
          // toast.success(response?.data?.message);
          if (response?.data?.access) {
            localStorage.setItem(
              "userAuth",
              JSON.stringify(response?.data?.access)
            );
            localStorage.setItem(
              "user_type",
              JSON.stringify(response?.data?.user_type)
            );
            localStorage.setItem(
              "userId",
              JSON.stringify(response?.data?.data?.id)
            );
            localStorage.setItem(
              "userName",
              JSON.stringify(response?.data?.data?.name)
            );
            localStorage.setItem(
              "userEmail",
              JSON.stringify(response?.data?.data?.email)
            );
            localStorage.setItem(
              "userContact",
              JSON.stringify(response?.data?.data?.mobile)
            );
          }
          if (response?.data?.refresh) {
            localStorage.setItem(
              "refreshtoken",
              JSON.stringify(response?.data?.refresh)
            );
          }
        } else {
          // alert(response?.data?.message)
          // toast.warning(response?.data?.message);
          reject(response);
          console.log("error", response?.data?.message)
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function forgetPasswordApi(data) {
  console.log(data)
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${BASE_URL}user/password-reset-request/request_reset/`,
        JSON.stringify({ email: data.email }),
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
          return response?.data;
        } else {
          reject(response);
          return response.data;
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );

}
export function resesndEmail(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/resend-mail`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

// reset password api

export function resetPasswordApi(data) {
  const { new_password, confirm_password, token, uidb64 } = data;
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${BASE_URL}user/password-reset-confirm`,
        `new_password=${new_password}&confirm_password=${confirm_password}&token=${token}&uidb64=${uidb64}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function verifyAccountApi(uuid, verifyToken) {

  console.log("api user", uuid, verifyToken)
  return new Promise((resolve, reject) =>
    axios
      .post(
        `${BASE_URL}user/account/verify-account/activate/${uuid}/${verifyToken}/`,
        {},
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
        // toast.error(error.message)
      })
  );
}

// export function getCategoriesApi() {
//   return new Promise((resolve, reject) =>
//     axios
//       .get('https://tc.arktini.com/lms/category/get_categories/', {
//         headers: {
//           'Accept': 'application/json',
//         },
//       })
//       .then(function (response) {
//         if (response.status === 200) {
//           resolve(response.data);
//         } else {
//           reject(response);
//         }
//       })
//       .catch(function (error) {
//         reject(error);
//       })
//   );
// }
export function typeOfCountryApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/add/country_list/`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function typeOfUsersApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/user-type`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function studentRegsiterApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/register`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
          toast.success(response?.data?.message);
          // console.log("signupdd",response?.data?.message)
        } else {
          // console.log("signupdderror",response?.data?.message)
          toast.error(response?.data?.message);
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function verifyEmailOtpApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/verify-code`, data)
      .then(function (response) {
        if (response?.status === 201) {
          toast.success(response?.data?.message);
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function forgetPasswordEnd(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/forgot-password`, data)
      .then(function (response) {
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function registerTeacherBySocial(data) {
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${BASE_URL}user/social-user/update`, data, config)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
          toast.success(response?.data?.message);
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function userProfileUpdate(data) {
  return new Promise((resolve, reject) =>
    axios
      .patch(`${BASE_URL}user/update/profile/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function instructorCategories(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/category`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function instructorSpecalities(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/category-sub?category=${data}`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

// courrse category start
export function courseCategory(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/category/add/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function courseCategoryList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/category/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function createSubjectCategory(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/subject/add/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function listSubjectCategory() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/subject/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function listSubjectBYCategoryId(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${BASE_URL}lms/subject/27da1bc1-f864-43e4-932a-319c49f9a698/subjects/`
      )
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

//course list
export function createCourse(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/course/create_course/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function courseList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/course/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function courseListCopy() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/course/get_level_wise/?level=beginner intermediate`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
//technology
export function technologyList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/technology/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

// certificates


export function createTechnology(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/technology/add_technology/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

// -------------------get user login detials---------------------
export async function getUserdetails(id) {
  try {
    const res = await axios.get(`${BASE_URL}user/profiles/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data.data && res.data.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get user login detials---------------------
// -------------------update user/student profile detials---------------------

export async function updateUserdetail(id, formDatas, token) {
 
  try {
    const { pincode, bio, city, dob, address, gender, age, image,country } = formDatas;
    const formData = new FormData();
    formData.append('user_id', id);
    formData.append('bio', bio);
    formData.append('city', city);
    formData.append('birth_date', dob);
    formData.append('address', address);
    // formData.append('pincode', Math.floor(parseInt(pincode)));
    formData.append('pincode', isNaN(pincode) ? undefined : Math.floor(parseInt(pincode)));
    formData.append('gender', gender);
    formData.append('age', age);
    formData.append('image', image);
    formData.append('country_name', country)

    const res = await axios.patch(`${BASE_URL}user/profiles/update_profile/`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json'
      },
    });

    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response.data;
    }
    console.error("Error updating profile:", error.message);
    return error.message;
  }
}


// export async function updateUserdetails(id, data) {
//   try {
//     const res = await axios.put(`${BASE_URL}user/profiles/update_profile/${id}`, data, {
//       headers: {
//         Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
//       },
//     });
//     return res.data.data && res.data.data;
//   } catch (error) {
//     if (error.response && error.response.status === 400) {
//       return error.response.data;
//     }
//     return error.message;
//   }
// }
// -------------------update user/student profile detials---------------------

// -------------------create category or sub-category------------
export async function createCategorySubcategory(data) {
  try {
    const formData = new FormData();
    formData.append('cat_name', data.cat_name);
    formData.append('sub_cat_name', data.sub_cat_name);
    formData.append('banner', data.banner);
    formData.append('topics', JSON.stringify(data.topics));
    const res = await axios.post(
      `${BASE_URL}lms/category/create_category/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    toast.success(res.data.message);
    return res.data;
  } catch (error) {
    toast.error(error.message);
    return error.message;
  }
}

// -------------------create category or sub-category------------

// -------------------update category and subcategory-------------------
// {id, cat_name, sub_cat_name, banner, topics}
export async function updateCategorySubcategory(data) {


  try {
    const res = await axios.put(
      `${BASE_URL}lms/category/update_category/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
          'Content-Type': 'multipart/form-data',
          'accept': 'application/json',
        },
      }
    );
    toast.success(res.data.message);
    return res.data;
  } catch (error) {
    toast.error(error.message);
    return error.message;
  }
}

//-------------------delete Category-------------------

export async function requireDeleteCategory(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/category/${id}/delete-category/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
          accept: "application/json",
        },
      }
    );
    if (res.status === 204) {
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    toast.error(error.message);
    return error.message;
  }
}

// -------------------get category list-------------------
export async function getCategories() {
  try {
    const response = await axios.get(`${BASE_URL}lms/category/get_categories/`);
    if (response) {
      return response?.data;
    }
    throw new Error(`HTTP error! status: ${response.status}`);
  } catch (error) {
    console.error("Error fetching categories:", error);
    return null;
  }
}
// -------------------get category list-----------------------------------------

// -------------------delete course category list-------------------
export async function deleteCategoryAndSubcategory(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/category/${id}/delete-category/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res.data.error) {
      toast.success(res.data.error);
    } else {
      toast.success("delete successfully!");
    }
  } catch (error) {
    return error.message;
  }
}
// -------------------delete course category list-------------------

// -------------------add course-------------------
export async function addCourse(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course/add/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data.error) {
      toast.success(res.data.error);
      return res.data.error;
    } else {
      toast.success("course add successfully!");
      return res.data.message;
    }
  } catch (error) {
    return error.message;
  }
}
// -------------------add course-------------------

// -------------------get course by id-------------------
export async function getCourseById() {
  const id = localStorage.getItem("courseId");
  try {
    const res = await axios.get(`${BASE_URL}lms/course/${id}/get/`, {
      // headers: {
      //   Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      // },
    });
    console.log("dkdk",res)
    if (res.data) {

      return res.data;
    }
  } catch (error) {
    return error.message;
  }
}
// -------------------get course by id-------------------

// -------------------get course sub category list-------------------
export async function getCourseSubCategoryList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/subject/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get course sub category list-------------------

// -------------------get course list--------------------------------
export async function getCourseList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/course/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get course list--------------------------------

export async function getCoursesByCategory() {
  try {
    const res = await axios.get('https://tc.arktini.com/lms/course/courses_by_category/', {
      headers: {
        accept: 'application/json',
      },
    });
    return res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get technology list----------------------------


export async function getTechnologyList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/technology`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get technology list----------------------------

// -------------------get users data---------------------------------
export async function getUsersData(user_type) {
  try {
    const res = await axios.get(
      `${BASE_URL}user/admin/user_list/?type=${user_type}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get users data---------------------------------

// -------------------update course data by id-----------------------
export async function updateCourseById(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/course/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------update course data by id-----------------------

// -------------------delete course data by id-----------------------
export async function deleteCourseById(id) {
  try {
    const res = await axios.delete(`${BASE_URL}lms/course/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res;
  } catch (error) {
    return error.message;
  }
}
// -------------------delete course data by id-----------------------

// -------------------create course content-----------------------
export async function createCourseContent(courseContent) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course/create_content/`, {
      courseContent,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------create course content-----------------------

// --------------------get course content by course Id-------------------------------------
export async function getCourseContent(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course-files/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res?.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

export async function getCourseContentById(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/course-files/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
} 

// --------------------get course content by course Id-------------------------------------
export async function getCourseFiles(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/course-files/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
      params: {
        course_pk: id,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}

// --------------------delete course content by Id-------------------------------------

// --------------------delete course content by Id-------------------------------------

export async function deleteCourseContent(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/course-files/${id}/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
          accept: "application/json",
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// --------------------get course content by course Id-------------------------------------

// -------------------update course content-----------------------
export async function updateCourseContent(id) {
  try {
    const res = await axios.put(
      `${BASE_URL}lms/course/update_content/?course_pk=${id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------update course content-----------------------

// -------------------add course videos---------------------------
export async function addCourseVideos(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course/course_video/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------add course videos---------------------------

// -------------------get course videos---------------------------
export async function getCourseVideos(id) {
  try {
    const res = await axios.get(
      `${BASE_URL}lms/course/video_list/?course=${id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get Teacher list by admin---------------------------
// -------------------get teacher list by admin---------------------------
export async function getTeacherListByAdmin() {
  try {
    const res = await axios.get(`${BASE_URL}user/admin/teacher_list/`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("userAuth")
        )}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}

//--------------------Approved user teacher... by admin --------------

export async function ApprovedUserTeacherByAdmin(userId, isActive) {
  if (!userId) {
    throw new Error("requireApprovedUserTeacherByAdmin: userId is required");
  }

  try {
    const res = await axios.patch(
      `${BASE_URL}user/admin/active_user/`,
      { user_id: userId, is_active: isActive },
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
          "Content-Type": "application/json",
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}

// -------------------get the details of teacher, student, corporate, university, admin---------------------------

// -------------------get user type list---------------------------

export async function getUserTypeListByAdmin(type) {
  if (!type) {
    throw new Error("getUserTypeList: type is required");
  }

  try {
    const res = await axios.get(`${BASE_URL}user/admin/user_type_list/?type=${type}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get teacher profile completed---------------
export async function getTeacherProfileComplete() {
  try {
    const res = await axios.get(
      `${BASE_URL}user/admin/teacher_profile_completed/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get teacher profile completed---------------

// -------------------get state list------------------------------
export async function getStateList() {
  try {
    const res = await axios.get(`${BASE_URL}user/add/state_list/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get state list------------------------------

// -------------------teacher approved by admin------------------------------
export async function teacherApprovedByAdmin(id) {
  try {
    const res = await axios.get(`${BASE_URL}user/admin/${id}/approve/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------teacher approved by admin------------------------------

// -------------------user logout--------------------------------------------
export async function logoutStudent(navigate) {
  try {
    const res = await axios.get(`${BASE_URL}user/profile/logout/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });

    if (res) {
      localStorage.clear();
      toast.success(res?.data?.message);
      navigate("/");
      window.location.reload();
    }
  } catch (error) {
    toast.error("Failed to logout. Please try again.");
  }
}
// -------------------user logout--------------------------------------------

// -------------------get enrollement----------------------------------------
export async function getEnrollement(student_id) {
  try {
    const res = await axios.get(
      `${BASE_URL}lms/enrollments/student/${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res?.data) {
      return res?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get enrollement-----------------------------------------

// -------------------add enrollement-----------------------------------------
export async function addEnrollement(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/enrollments/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
//--------------------get payment-share-------------------------------------
export async function getPaymentShare() {
  try {
    const res = await axios.get(`${BASE_URL}lms/Payment-share/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
//--------------------post payment-share-------------------------------------

export async function postPaymentShare(data) {
  try {
    const res = await axios.post(
      `${BASE_URL}lms/Payment-share/`,
      data,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data) {
      return res;
    }
  } catch (error) {
    console.log(error?.message);
  }
}
// -------------------add enrollement-----------------------------------------

// -------------------get payment details-----------------------------------------
export async function getPayment() {
  try {
    const res = await axios.get(`${BASE_URL}lms/payment/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
// -------------------get payment details-----------------------------------------

// -------------------add payment details-----------------------------------------
export async function addPayment(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/payment/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add payment details-----------------------------------------

// student payments api 
export async function getPaymentApi(student_id) {
  try {
    // Ensure student_id is provided
    if (!student_id) {
      throw new Error("Student ID is required");
    }

    // Retrieve token from localStorage
    const userAuth = localStorage.getItem("userAuth");
    if (!userAuth) {
      throw new Error("User is not authenticated. Token is missing.");
    }
    const token = JSON.parse(userAuth);

    // Perform the GET request
    const res = await axios.get(`${BASE_URL}lms/payment/students/${student_id}/payments/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Payment data fetched successfully:", res.data.results);
    return res.data.results;
  } catch (error) {
    console.error("Error fetching payments:", error.message);

    // Detailed error logging for debugging
    if (error.response) {
      console.error("Response Error:", error.response.data);
    }
  }
};



// -------------------create subadmin-----------------------------------------
export async function createSubAdmin(data) {
  try {
    const res = await axios.post(`${BASE_URL}user/subadmin/post/`, data, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });

    // Handle success response
    if (res.data && res.data.success) {
      toast.success("Sub-admin created successfully!");
    }
  } catch (error) {
    // Handle error response
    if (error.response) {
      const errMsg = error.response.data?.message?.email?.[0];
      if (errMsg) {
        toast.error(errMsg); // Show the specific error message (e.g., email already exists)
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } else {
      console.log("error", error.message); // General error logging
      toast.error("Network error or server is down.");
    }
  }
}

// -------------------create subadmin-----------------------------------------

// -------------------get sub admin data-----------------------------------------
export async function getSubAdmin() {
  try {
    const res = await axios.get(`${BASE_URL}user/admin/subadmin_list/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get sub admin data-----------------------------------------

// -------------------create category and sub category-----------------------------------------
export async function createCategory(data) {
  try {
    const res = await axios.post(
      `${BASE_URL}lms/category/create_category/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------create category and sub category-----------------------------------------

// -------------------delete category by id-----------------------------------------
export async function deleteCategoryById(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/category/${id}/delete-category/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
    return true;
  } catch (error) {
    console.log("error", error.message);
  }
  return false;
}
// -------------------delete category by id-----------------------------------------

// -------------------add course 2nd step-------------------------------------------
export async function addCoursePartial(courseInformation) {
  const formData = new FormData();
  formData.append("title", courseInformation.title);
  formData.append("description", courseInformation.description);
  formData.append("instructor", courseInformation.instructor);
  formData.append("category", courseInformation.category);
  formData.append("level", courseInformation.level);
  formData.append("duration", courseInformation.duration);
  formData.append("banner", courseInformation.banner);
  formData.append("price", courseInformation.price);
  formData.append("discount_percentage", courseInformation.discount_percentage);
  formData.append("skills", JSON.stringify(courseInformation.skills));
  formData.append("mode", courseInformation.mode);
  formData.append("is_active", courseInformation.is_active);
  formData.append("visibility", courseInformation.visibility);
  formData.append("assignment_link", courseInformation.assignment_link);
  formData.append("about", JSON.stringify(courseInformation.about));
  formData.append("outcomes", JSON.stringify(courseInformation.outcomes));
  formData.append("modules", JSON.stringify(courseInformation.modules));
  try {
    const res = await axios.post(`${BASE_URL}lms/course/add/`, formData, {
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data;
  } catch (error) {
    console.log("error", error.message);
    return Promise.reject(error);
  }
}


//--------------------update Course data by id------------------------//

export async function updateCourseByCourseId(id, data) {
  console.log("first", id, "second", data);
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (key === "skills" || key === "about" || key === "outcomes") {
      try {
        // Ensure the value is parsed and valid JSON
        const parsedValue = Array.isArray(data[key]) 
          ? data[key] 
          : JSON.parse(data[key].replace(/\\/g, "")); // Remove escaped backslashes
          console.log(key, JSON.stringify(parsedValue))
        formData.append(key, JSON.stringify(parsedValue)); // Add cleaned JSON
      } catch (err) {
        console.error(`Error parsing field ${key}:`, err);
      }
    } else if (key === "modules"){
      formData.append(key, JSON.stringify(data.modules));
    } else {
      formData.append(key, data[key]);
    }
  });

  console.log("Cleaned FormData:", formData);

  try {
    const res = await axios.patch(`${BASE_URL}lms/course/${id}/update/`, formData, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data) {
      // toast.success(res.data.message || res.data.success);
      return res.data;
    }
  } catch (error) {
    console.log("Error during API call:", error.message);
  }
}


//--------------------get paymet List-----------------------//
// -------------------add course 2nd step-------------------------------------------

// -------------------add course 3rd step-------------------------------------------
export async function addCourseFull(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/content/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add course 3rd step-------------------------------------------

// -------------------get course 3rd step-------------------------------------------
export async function getCourseFull(data) {
  try {
    const res = await axios.get(`${BASE_URL}lms/content/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

//--------------------get Course Modules by course Id-----------------------//
export async function getCourseModulesByCourseId(courseId) {
  console.log(courseId)
  try {
    console.log(courseId)
    const res = await axios.get(`${BASE_URL}lms/course/modules-by-course/?course_id=${courseId}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

// ------------------get video by the file id---------------------------

export async function getVideoByFileId(fileId) {
  try {
    const res = await axios.get(`${BASE_URL}lms/stream-video/${fileId}/`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    console.log("response", res)
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get course 3rd step-------------------------------------------

// -------------------get dashboard data-----------------------------------------
export async function getDashboardData() {
  try {
    const res = await axios.get(`${BASE_URL}lms/summary/get_summary/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res?.data?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get dashboard data-----------------------------------------

// -------------------get course list-----------------------------------------
export async function getCourseListDashboard(params) {
  try {
    // console.log("get all courses")
    const response = await axios.get(`${BASE_URL}lms/course/list_courses/`, {
      headers: {
        accept: 'application/json',
      },
      params: {
        ...params,
      },
    });

    const ids = response.data.results.map(item => item.id);
    const meetings = await getMeetingByCourseIds(ids.join(','));
    // console.log("meetings", meetings);

    response.data.results.forEach(item => {
      const meeting = meetings?.results?.find(meeting => meeting.course === item.id);
      if (meeting) {
        item.meeting = meeting;
      }
    });

    return response?.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return null;
  }
}

// -------------------get meeting by course ids-----------------------------------------
export async function getMeetingByCourseIds(courseIds) {
  try {
    const res = await axios.get(`${BASE_URL}lms/zoom-meeting/get-meetings-by-course-ids/?course_ids=${courseIds}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}


// -------------------create zoom meeting-----------------------------------------
export async function createZoomMeeting(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/zoom-meeting/create-zoom-meeting/`, data, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
        "Content-Type": "application/json",
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

// -------------------get meeting by course ids-----------------------------------------

// -------------------get course list-----------------------------------------

// -------------------get user request-----------------------------------------
export async function getUserRequest(params) {
  try {
    const res = await axios.get(`${BASE_URL}lms/requests/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
      params: {
        ...params,
      },
    });
    if (res.data) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get user request-----------------------------------------

// -------------------create user request-----------------------------------------
export async function createUserRequest(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/requests/`, data, {
      // headers: {
      //   Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      // },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------create user request-----------------------------------------

// -------------------get newsLetter information---------------------------------------
export async function getNewLetterInformation() {
  try {
    const res = await axios.get(`${BASE_URL}lms/newsletters/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get newsLetter information---------------------------------------

// -------------------delete newsLetterById---------------------------------------
export async function deleteNewsLetterById(id) {
  try {
    const res = await axios.delete(`${BASE_URL}lms/newsletters/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------delete newsLetterById---------------------------------------

// -------------------edit newsLetterById---------------------------------------
export async function editeNewsLetterById(data) {
  // Function to fetch image and convert it to a Blob
  async function fetchImageAsBlob(imageUrl) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], "image.png", { type: blob.type });
  }
  try {
    const imageFile = await fetchImageAsBlob(data.image);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("image", imageFile);
    const response = await axios.put(
      `${BASE_URL}lms/newsletters/${data.id}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );

    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error updating newsletter:", error);
  }
}
// -------------------edit newsLetterById---------------------------------------

// -------------------getCourseProgress---------------------------------------
export async function getCourseProgress(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/progress/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------getCourseProgress--------------------------------------

// -------------------add Course Progress---------------------------------------
export async function addCourseProgress(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/progress/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add Course Progress--------------------------------------
// .................pagination....................

// --------------------send notification-------------------------------------
export async function sendNotificationByJson(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/notifications/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------send notification-------------------------------------

// --------------------get notification-------------------------------------
export async function getNotification() {
  try {
    const res = await axios.get(`${BASE_URL}/lms/notifications/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get notification-------------------------------------
export async function getNotificationById(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/notifications/${id}/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// .......................getWishList...........................
export async function getWishList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/wishlist/by-user-id/?user_id=${localStorage.getItem("userId")}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {

      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// .......................postWishList...........................
export async function postWishList(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/wishlists/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// .......................deleteWishList...........................
export async function deleteWishList(id) {
  try {
    const res = await axios.delete(`${BASE_URL}lms/wishlists/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// .......................getWishListById................
// --------------------create report-------------------------------------
export async function createReport(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/reports/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------'updateReportStatus-------------------------------------
export async function updateReportStatus(id, data) {
  try {
    const res = await axios.put(`${BASE_URL}lms/reports/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
      params: {
        user_id: localStorage.getItem("userId"),
      },

    });
    console.log("data", data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------create report-------------------------------------

// --------------------get report-------------------------------------
export async function getReport() {
  try {
    const res = await axios.get(`${BASE_URL}lms/reports/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get reports-------------------------------------
export async function getReports(params) {
  try {
    const res = await axios.get(`${BASE_URL}lms/reports/get_all_reports/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
      params:{
        ...params
      }
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------create course content-------------------------------------
export async function addCourseModule(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course-files/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------create course content-------------------------------------

// --------------------dynamic course rate--------------------------------------
export async function fetchExchangeRate(country) {
  try {
    const response = await axios.get(
      `https://api.exchangerate-api.com/v4/latest/INR`
    ); // Replace with your API
    const rate = response.data.rates[country];
    return rate;
  } catch (error) {
    console.error("Error fetching exchange rates", error);
  }
}
// --------------------dynamic course rate--------------------------------------

// --------------------get course by category-------------------------------------
export async function getCourseByCatId(category) {
  try {
    const res = await axios.get(`${BASE_URL}lms/course/category/`, {
      params: {
        category: category,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get course by category-------------------------------------

// --------------------upload bulk courses----------------------------------------
export async function addBulkCourse(data) {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.post(
      `${BASE_URL}lms/bulk-upload/bulk-upload/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${parsedToken}`,
        },
      }
    );

    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------upload bulk courses----------------------------------------

// --------------------add assignment----------------------------------------
export async function addAssignment(data) {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.post(`${BASE_URL}lms/assignments/`, data, {
      headers: {
        Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------add assignment----------------------------------------

// --------------------get assignment----------------------------------------
export async function getAssignment() {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.get(`${BASE_URL}lms/assignments/`, {
      headers: {
        Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (res) {
      return res?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get assignment----------------------------------------

// --------------------get quizzes----------------------------------------
export async function getQuiz() {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.get(`${BASE_URL}lms/quizzes/`, {
      headers: {
        Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (res) {
      return res?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get quizzes----------------------------------------

// --------------------add quizzes----------------------------------------
export async function addQuiz(data) {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.post(`${BASE_URL}lms/quizzes/`, data, {
      headers: {
        Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------add quizzes----------------------------------------

//--------------------add multiple quizzes----------------------------------------
export async function addMultipleQuizzes(assignmentId, quizzes) {
  try {
    const response = await axios.post(
      `${BASE_URL}/lms/quizzes/add-multiple/?assignment_id=${assignmentId}`,
      quizzes,
      {
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
}


// --------------------get course by student Id---------------------------
export async function getCourseByStudentId(student_id) {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.get(
      `${BASE_URL}lms/course/student/?student_id=${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${parsedToken}`,
        },
      }
    );

    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
}

// --------------------get course by student Id---------------------------

// --------------------get course by student Id---------------------------
export async function getCourseProgressByStudentId(student_id) {
  try {
    const token = localStorage.getItem("userAuth");
    const parsedToken = token ? JSON.parse(token) : null;

    const res = await axios.get(
      `${BASE_URL}lms/progress/student/${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${parsedToken}`,
        },
      }
    );

    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
}

// --------------------get course by student Id---------------------------

// studentDashboardApi

export async function studentDashboardApi(studentId) {
  try {
    const token = localStorage.getItem("userAuth");
    // const studentId = localStorage.getItem("userId")
    const parsedToken = token ? JSON.parse(token) : null;
    if (!parsedToken) {
      throw new Error("Authorization token not found");
    }
    const response = await axios.get(`${BASE_URL}/lms/student-dashboard/total-duration-completed-courses/${studentId}/`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${parsedToken}`,
        }
      })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.log('error in studentdashboardapi in auth:', error)
  }
}



// Category api's
export async function createCategoryApi(formData) {
  try {
    const res = await axios.post(
      `${BASE_URL}/lms/category/create_category/`,
      formData,
      {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem("userAuth").replace(/['"]+/g, '')}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log("Error creating category:", error.message);
    throw error;
  }
}

// -------------------get all categories-----------------------------------------
export async function getCategoriesApi(params) {
  try {
    const res = await axios.get(`${BASE_URL}lms/category/get_categories/`, {
      headers: {
        accept: "application/json",
      },
      params: {
        ...params,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

export async function updateCategoryApi(formData) {
  // const { id, cat_name, sub_cat_name, banner } = data;
  // const formData = new FormData();
  // formData.append("cat_name", cat_name);
  // formData.append("sub_cat_name", sub_cat_name);
  // formData.append("banner", banner);
  // formData.append("id", id);

  try {
    const res = await axios.put(
      `${BASE_URL}lms/category/update_category/`,
      formData,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("userAuth").replace(/['"]+/g, '')}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log("Error updating category:", error.message);
    throw error;
  }
}


// -------------------delete category by id-----------------------------------------
export async function deleteCategoryApi(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/category/${id}/delete-category/`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log("Error deleting category:", error.message);
    throw error;
  }
}


// -------------------get Admin Dashboard data-----------------------------------------

export async function getCourseProgressApi() {
  try {
    const res = await axios.get(`${BASE_URL}lms/courseprogress/course-progress/`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("userAuth").replace(/['"]+/g, '')}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
};

// get progress by student id and course id
export async function getProgressByCourseIdAndStudentId(courseId, studentId) {
  try {
    const token = localStorage.getItem("userAuth")
    const studentToken = JSON.parse(token);
    const res = await axios.get(`${BASE_URL}/lms/progress/course/${courseId}/${studentId}/`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${studentToken}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
};

// update Progress

export async function updateProgress(progressId, courseId, studentId, fileId) {
  try {
    const token = localStorage.getItem("userAuth")
    const studentToken = JSON.parse(token);
    const res = await axios.put(`${BASE_URL}lms/progress/${progressId}/`,
      {
        course: courseId,
        student: studentId,
        file: fileId
      },
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${studentToken}`,
          "Content-Type": "application/json"
        }
      }
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
};

// request refund for student/payment ---------------
export async function refundRequest(data) {

  try {
    // console.log("km.,m",data)
    const token = localStorage.getItem("userAuth")
    const studentToken = JSON.parse(token);
    const res = await axios.post(`${BASE_URL}/lms/refund-request/`,
      data,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${studentToken}`,
          "Content-Type": "application/json",
        },

      });
    if (res.status === 200 || res.status === 201) {
      toast.success("Refund request sent successfully");
     
      // console.log("Request successful:", res.data);
    } else {
      toast.success("Refund request not sent something wrong!");
    }
  } catch (error) {
    console.log("Error:", error.message)
  }
};

// getting refund ---

export async function getAllRefunds () {
  try {
    const token = localStorage.getItem("userAuth")
    const studentToken = JSON.parse(token);
    const res = await axios.get(`${BASE_URL}/lms/refund-request/`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${studentToken}`,
        },
      });

    // console.log('kk', res.data.results);
    return res.data.results;
  } catch (error) {
    console.log("Error:", error.message)

  }
}


// -----------WISHlIST------------

export async function studentPostWish(data) {
  try {
    const token = localStorage.getItem("userAuth");
    const studentToken = token ? JSON.parse(token) : null;

    if (!studentToken) {
      throw new Error("User is not authenticated");
    }

    // Create FormData object
    const formData = new FormData();
    formData.append("user", data.user);
    formData.append("course", data.course);

    const res = await axios.post(`${BASE_URL}/lms/wishlist/`, formData, {
      headers: {
        Authorization: `Bearer ${studentToken}`, 
        "Content-Type": "multipart/form-data", 
      },
    });

    console.log("Wishlist response:", res.data);
    return res.data;
  } catch (error) {
    console.error("Error adding to wishlist:", error.message);
    throw error; // Rethrow for caller to handle
  }
}

