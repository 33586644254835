import React from "react";
import django from "../../assests/images/courseicons/Django.png";
import pythonicon from "../../assests/images/courseicons/python.png";
import nodejsicon from "../../assests/images/courseicons/Nodejs.png";
import mongodbicon from "../../assests/images/courseicons/MongoDB.png";
import powerbi from "../../assests/images/courseicons/PowerBI.png";
import ricon from "../../assests/images/courseicons/Ricon.png";
import reacticon from "../../assests/images/courseicons/Reacticon.png";
import sqlicon from "../../assests/images/courseicons/sqlicon.png";
import Tableauicon from "../../assests/images/courseicons/Tableauicon.png";
import { NavLink } from "react-router-dom";

const topics = [
  { name: "R", icon: django },
  { name: "Python", icon: pythonicon },
  { name: "React JS", icon: reacticon },
  { name: "Mongo DB", icon: mongodbicon },
  { name: "C#", icon: Tableauicon },
  { name: "Redhat", icon: sqlicon },
  { name: "AWS", icon: ricon },
  { name: "Azure", icon: powerbi },
  { name: "Angular", icon: nodejsicon },
];

const PopularTopics = () => {


  return (
    <div className="bg-gradient-to-r flex-col items-center lg:flex lg:flex-row xl:flex xl:flex-row from-blue-900 to-cyan-900 p-8 justify-around">
      <div className="text-white flex flex-col items-center md:items-start gap-3">
        <h2 className="text-2xl font-bold">Popular topics to learn now</h2>
        <NavLink
          to={{
            pathname: "/courses",
            search: "?query=free",
          }}

          className="w-[13rem] cursor-pointer text-black text-center  bg-white hover:text-white rounded-sm py-2 px-5 hover:bg-blue-900 hover:border transition duration-300"
        >
          View All Courses
        </NavLink>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-4 mt-6">
        {topics.map((topic, index) => (
          <div key={index} className="">
            <img
              src={topic.icon}
              alt={topic.name}
              className="w-20 h-20 rounded-lg transition duration-300 ease-in-out border-2 border-transparent hover:border-cyan-300"
            />
          </div>
        ))}
      </div>


    </div>
  );
};

export default PopularTopics;
