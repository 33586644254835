import React, { useState } from "react";
import { FaRegEye } from "react-icons/fa6";
import { IoEyeOffOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { forgetPasswordEndAsync } from "../../../store/features/AuthSlice";
import validator from "validator";
import { changeShowLogin } from "../../../store/features/linkedinLoginSlice";

const NewPassword = (props) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const [showConfirmPassIcon, setShowConfirmPassIcon] = useState(false);
  const [createNewPassword, setCreateNewPassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const validatePassword = (password) => {
    if (
      validator.isStrongPassword(password, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must have at least 6 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)",
      }));
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setCreateNewPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "newPassword") {
      validatePassword(value);
    }
  };
  console.log(createNewPassword, "createNewPassword");
  const changeIconPassWord = () => {
    setShowPasswordIcon((data) => !data);
  };
  const changeIconConfirmPassWord = () => {
    setShowConfirmPassIcon((data) => !data);
  };
  const handleCloseIcon = () => {
    dispatch(changeShowLogin(false));
    props.setForgetPassword("login");
  };
  const handleSavePassword = () => {
    dispatch(changeShowLogin(true));
    props.setShowNewPasswordCard(false);
    props.setVerifyEmail(false);
    props.setForgetPassword("login");
    const data = {
      password: createNewPassword.newPassword,
      confirm_password: createNewPassword.confirmNewPassword,
      email: props.forgetPassLogin,
    };
    dispatch(forgetPasswordEndAsync(data));
  };
  return (
    <div>
      <div>
        <div
          className="flex justify-end cursor-pointer"
          onClick={handleCloseIcon}
        >
          <img src={process.env.PUBLIC_URL + "/images/x.png"} alt="cross" />
        </div>
        <div className="flex justify-center mt-[-12px]  items-center">
          <h2 className="font-semibold text-gray-900 text-2xl max-sm:text-xl">
            Create New Password
          </h2>
        </div>
        <div className="flex justify-center my-5  flex-col items-center">
          <img
            src={process.env.PUBLIC_URL + "/images/forgetPassword.png"}
            alt="cross"
          />
          <div className="w-72  mt-2 px-2">
            <p className="block text-sm text-gray-900 font-semibold mb-4 text-center">
              Your New password must be different from previously used Password
            </p>
          </div>
        </div>
        <div className="mb-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Password
          </label>
          <div className=" relative">
            <input
              type={showPasswordIcon ? "text" : "password"}
              value={createNewPassword.newPassword}
              name="newPassword"
              onChange={handleChange}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="Password"
            />
            <span
              onClick={changeIconPassWord}
              className="absolute  right-4 bottom-3 cursor-pointer"
            >
              {showPasswordIcon ? <IoEyeOffOutline /> : <FaRegEye />}
            </span>
          </div>
          {createNewPassword.newPassword === "" ? (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          ) : (
            <span
              className={`block text-sm  ${
                errors == "" ? "" : "text-red-600 pt-1"
              }`}
            >
              {errors.password}
            </span>
          )}
        </div>
        <div className="mb-2 mt-5">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Confirm Password
          </label>
          <div className=" relative">
            <input
              type={showConfirmPassIcon ? "text" : "password"}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="Confirm Password"
              name="confirmNewPassword"
              value={createNewPassword.confirmNewPassword}
              onChange={handleChange}
            />
            <span
              onClick={changeIconConfirmPassWord}
              className="absolute  right-4 bottom-3 cursor-pointer"
            >
              {showConfirmPassIcon ? <IoEyeOffOutline /> : <FaRegEye />}
            </span>
          </div>
          {createNewPassword.confirmNewPassword !==
          createNewPassword.newPassword ? (
            <span className="block text-sm text-red-500 pt-1">
              password should be match !
            </span>
          ) : (
            createNewPassword.confirmNewPassword === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )
          )}
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-5 rounded"
          onClick={handleSavePassword}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default NewPassword;
