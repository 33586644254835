import React, { useState, useEffect } from "react";
import VerifyEmail from "../verifyEmail/VerifyEmail";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordAsync } from "../../../store/features/AuthSlice";
import validator from "validator";
import { toast } from "react-toastify";
import { changeShowLogin } from "../../../store/features/linkedinLoginSlice";

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const [forgetPassLogin, setForgetPassLogin] = useState("");
  const [errors, setErrors] = useState({});
  const [nextMove, setNextMove] = useState(false);
  const errorMessage = useSelector((state) => state.auth.errorMsg);
  const validateEmail = (email) => {
    if (validator.isEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
      setNextMove((data) => !data);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      setNextMove((data) => !data);
    }
  };
  const handleForgetPassword = () => {
    const data = { email: forgetPassLogin };
    if (forgetPassLogin !== "" && nextMove === true) {
      const fogetApi = dispatch(forgetPasswordAsync(data));
      handleApiResponse(fogetApi);
    }
  };

  const handleApiResponse = (promise) => {
    promise
      .then((response) => {
        props.setVerifyEmail(true);
        console.log("degug line 41", response); // Output the message
      })
      .catch((error) => {
        console.error("API call failed: ", error); // Handle any errors
      });
  };

  const handleCloseIcon = () => {
    dispatch(changeShowLogin(false));
    props.setForgetPassword("login");
  };
  useEffect(() => {
    if (forgetPassLogin !== "" && forgetPassLogin !== undefined)
      validateEmail(forgetPassLogin);
  }, [forgetPassLogin]);
  useEffect(() => {
    if (errors.email === "") {
      setNextMove(true);
    } else {
      setNextMove(false);
    }
  }, [errors.email]);

  return (
    <div>
      {props.verifyEmail === false ? (
        <div>
          <div
            className="flex justify-end cursor-pointer"
            onClick={handleCloseIcon}
          >
            <img src={process.env.PUBLIC_URL + "/images/x.png"} alt="cross" />
          </div>
          <div className="flex justify-center mt-[-12px]  items-center">
            <h2 className="font-semibold text-gray-900 text-2xl max-sm:text-xl">
              Forget Password
            </h2>
          </div>
          <div className="flex justify-center my-5  flex-col items-center">
            <img
              src={process.env.PUBLIC_URL + "/images/forgetPassword.png"}
              alt="cross"
            />
            <div className="w-72  mt-2 px-2">
              <p className="block text-sm text-gray-900 font-semibold mb-4 text-center">
                Please Enter your Email address to receive a verification code
              </p>
            </div>
          </div>
          <div className="mb-4 mt-4">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Email Address
            </label>
            <input
              value={forgetPassLogin}
              name="forgetPassLogin"
              type="email"
              onChange={(e) => {
                setForgetPassLogin(e.target.value);

                validateEmail(forgetPassLogin);
              }}
              className="border bg-[white] border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="name@gmail.com"
            />
            {forgetPassLogin == "" ? (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            ) : (
              <span
                className={`block text-sm  ${
                  errors == "" ? "" : "text-red-600 pt-1"
                }`}
              >
                {errors.email}
              </span>
            )}
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-5 rounded"
            onClick={() => handleForgetPassword()}
          >
            Send Verification Mail
          </button>
          <div className="flex justify-center items-center mt-3">
            <button
              className="py-2 text-center underline  "
              onClick={() => props.setForgetPassword("login")}
            >
              Go Back <span>&raquo;</span>
            </button>
          </div>
        </div>
      ) : (
        <VerifyEmail
          setVerifyEmail={props.setVerifyEmail}
          setForgetPassword={props.setForgetPassword}
          forgetPassLogin={forgetPassLogin}
        />
      )}
    </div>
  );
};

export default ForgetPassword;
