import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserdetails, studentDashboardApi } from './authApi';
// import axios from "axios";
// const BASE_URL = process.env.REACT_APP_BASEURL;



// to get student details
export const getStudentDetailsAsync = createAsyncThunk(
    "student/getUserDetails",
    async (data, { rejectWithValue }) => {
        try {
            const response = await getUserdetails(data);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

// getting studentdashboard api 

export const getStudentDashboard = createAsyncThunk(
    "studentDashboard",
    async (data, {rejectWithValue})=>{
        try {
            const result = await studentDashboardApi(data);
            return result
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
)

const initialState = {
    studentDetails: {},
    loading: false,
    error: null,
    updateSuccess: false,  
    studentDashboard : {}
};

const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        resetUpdateSuccess: (state) => {
            state.updateSuccess = false; 
        }
    },
    extraReducers: (builder) => {
        // Get student details
        builder.addCase(getStudentDetailsAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getStudentDetailsAsync.fulfilled, (state, action) => {
            state.loading = false;
            state.studentDetails = action.payload;
        });
        builder.addCase(getStudentDetailsAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
          

        // get student dashboard api
        builder.addCase(getStudentDashboard.pending,(state)=>{
            state.loading = true;
            state.error= null;
        })
        .addCase(getStudentDashboard.fulfilled, (state,action)=>{
            state.loading = false;
            state.studentDashboard= action.payload;
        })
        .addCase(getStudentDashboard.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.payload
        })
    },
});

export const { resetUpdateSuccess } = studentSlice.actions; // Export the reset action
export default studentSlice.reducer;



