import React, { useState } from "react";
import BottomNav from "../../navigation/BottomNavigation";

import Forindividual from "../../../../studentdashboard/Forindividual";
import Footer from "../../../Footer/Footer";

const ForIndividuals = () => {
  return (
    <>
      {/* <BottomNav /> */}
      <Forindividual />
      <Footer />
    </>
  );
};

export default ForIndividuals;
