import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

const LinkdinLogin = () => {
  const handleLogin = () => {
    const clientId = "77dvnc9s163hhz";
    const LINKEDIN_CLIENT_SCERET = "WPL_AP0.9HdYwh1EdrAIAhTK.MTIzMzE3NTk1";
    const redirectUri = "http://localhost:3000";
    const scope = "openid profile email w_member_social"; // Specify the permissions you need

    // Construct the OAuth URL
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}`;
    // Redirect the user to LinkedIn
    window.location.href = oauthUrl;
  };

  return (
    <button className="linkedin-btn" onClick={handleLogin}>
      <FaLinkedin className="linkedin-icon" />
      Login with LinkedIn
    </button>
  );
};

export default LinkdinLogin;
