import React, { memo } from "react";
import { FaCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";

const RadioWithCheck = (props) => {
  const userType = useSelector((state) => state.auth.userType);

  const loading = useSelector((state) => state.auth.loading);
  const errorMsg = useSelector((state) => state.auth.errorMsg);
  const { selectedOption, handleOptionChanges } = props;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMsg) {
    return <div>Error: {errorMsg}</div>;
  }

  return (
    <div className="flex items-center flex-wrap">
      {userType &&
        userType.map((type, ind) => (
          <div key={ind} className="flex items-center mr-3 mb-2">
            <input
              type="radio"
              id={type?.name}
              name={type?.name}
              value={type?.name}
              checked={selectedOption == type?.name}
              onChange={() => handleOptionChanges(type?.name)}
              className="hidden"
            />
            <label
              htmlFor={type?.name}
              className="h-4 w-4 rounded-full border border-gray-400 flex items-center justify-center cursor-pointer mr-2"
            >
              {selectedOption == type?.name && (
                <FaCheck className="text-[white] bg-blue-600 rounded-full" />
              )}
            </label>
            <span className="text-gray-700 capitalize">{type?.name}</span>
          </div>
        ))}
    </div>
  );
};

export default memo(RadioWithCheck);
