import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrorMsg,
  instructorCatAsync,
  instructorSpAsync,
  showErrorMsg,
  studentRegisterAsync,
} from "../../../../store/features/AuthSlice";
import { getCategories } from "../../../../store/authApi";
import { toast } from "react-toastify";

const FinalInstructor = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const dispatch = useDispatch();
  const [showErr, setShowErr] = useState(false);
  useEffect(() => {
    getCategories().then((res) => setCategoryData(res?.data));
  }, []);

  useEffect(() => {
    dispatch(instructorCatAsync());
  }, []);

  const userType = useSelector((state) => state.auth.userType);
  const insSp = useSelector((state) => state.auth.instructorSp);
  const insCat = useSelector((state) => state.auth.instructorCat);
  const [categoryName, setCategoryName] = useState("");

  const {
    instructorSignup,
    setInstructorSignup,
    selectedOption,
    NavigateOnLogin,
  } = props;

  const userTypeId = userType.find((item) => item.name === selectedOption)?.id;

  const handleChange = (e) => {
    const { name, type, checked, files } = e.target;
    const categoryIdName = e.target.value.split(",");
    const value = categoryIdName[0];
    const categoryName = categoryIdName[1];
    const courseName = insCat?.map((item) => item.name);
    if (courseName?.includes(categoryName)) setCategoryName(categoryName);
    if (type === "file" && files?.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setInstructorSignup((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else if (value.trim() !== "") {
      setInstructorSignup((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
      
    } else {
      setInstructorSignup((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
  };

  // useEffect(() => {
  //   insCat?.forEach((el) => {
  //     if (el?.id === instructorSignup?.category) {
  //       dispatch(instructorSpAsync(el?.name));
  //     }
  //   });
  // }, [instructorSignup?.category, dispatch, insCat]);
  useEffect(() => {
    if (categoryName) dispatch(instructorSpAsync(categoryName));
  }, [categoryName]);

  const handleEvent = () => {
    if (!instructorSignup.specialization) {
      dispatch(showErrorMsg("First Select Category"));
      setShowErr((data) => !data);
    } else {
      dispatch(clearErrorMsg());
      setShowErr((data) => !data);
    }
  };

  const handleSubmitForm = (event) => {
    console.log(event);
    event.preventDefault();
    const {
      instructorFullName,
      instructorEmail,
      country,
      password,
      confirmPassword,
      qualification,
      income,
      hours,
      category,
      specialization,
      subject,
      idProof,
      educationalDocs,
    } = instructorSignup;

    const data = {
      type: userTypeId,
      name: instructorFullName,
      email: instructorEmail,
      mobile: instructorSignup?.contactNumber?.slice(2, 12),
      country: country,
      profile: { image: instructorSignup?.image },
      password: password,
      confirm_password: confirmPassword,
      teacher_details: {
        qualification: qualification,
        exp_income: income,
        avail_hours: hours,
        category: category,
        specialization: specialization,
        subject: subject,
        id_proof: idProof, // File object
        education_copy: educationalDocs, // File object
      },
    };
  
    if (
      instructorFullName &&
      instructorEmail &&
      country &&
      password &&
      confirmPassword &&
      qualification &&
      income &&
      hours &&
      category &&
      subject &&
      idProof &&
      educationalDocs
    ) {
    
     dispatch(studentRegisterAsync(data));
     
      NavigateOnLogin();
    } else {
      toast.error("All fields are required!!");
    }
  
  };

  return (
    <div>
      <form onSubmit={handleSubmitForm} className="w-full">
        <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1">
          <div className="mt-6">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Highest Qualification
            </label>
            <div className="relative">
              <select
                name="qualification"
                value={instructorSignup.qualification}
                onChange={handleChange}
                className="block appearance-none border bg-white border-gray-300 rounded-md px-3 py-2 w-full focus:outline-0 text-gray-600 text-sm"
              >
                <option
                  value=""
                  disabled
                  selected
                  className="text-gray-600 text-sm"
                >
                  Select Qualification
                </option>
                <option value="post graduate" className="text-gray-600 text-sm">
                  Post Graduate
                </option>
                <option value="graduate" className="text-gray-600 text-sm">
                  Graduate
                </option>
                <option value="other" className="text-gray-600 text-sm">
                  Other
                </option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <img
                  src={process.env.PUBLIC_URL + `/images/Chevron Down.png`}
                  alt="drop-image"
                />
              </div>
            </div>
            {instructorSignup.qualification === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>
          <div className="max-sm:mt-0 mt-6">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Available Hours
            </label>
            <div className="relative">
              <select
                name="hours"
                value={instructorSignup.hours}
                onChange={handleChange}
                className="block appearance-none border bg-white border-gray-300 rounded-md px-3 py-2 w-full focus:outline-0 text-gray-600 text-sm"
              >
                <option
                  value=""
                  disabled
                  selected
                  className="text-gray-600 text-sm"
                >
                  Select Hours
                </option>
                <option value="1-2" className="text-gray-600 text-sm">
                  1-2 Hours
                </option>
                <option value="2-3" className="text-gray-600 text-sm">
                  2-3 Hours
                </option>
                <option value="3-4" className="text-gray-600 text-sm">
                  3-4 Hours
                </option>
                <option value="4-5" className="text-gray-600 text-sm">
                  4-5 Hours
                </option>
                <option value="5-6" className="text-gray-600 text-sm">
                  5-6 Hours
                </option>
                <option value="6-7" className="text-gray-600 text-sm">
                  6-7 Hours
                </option>
                <option value="7-8" className="text-gray-600 text-sm">
                  7-8 Hours
                </option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <img
                  src={process.env.PUBLIC_URL + `/images/Chevron Down.png`}
                  alt="drop-image"
                />
              </div>
            </div>
            {instructorSignup.hours === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>
          <div className="mt-0">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Expected Income
            </label>
            <input
              type="number"
              name="income"
              value={instructorSignup.income}
              onChange={handleChange}
              className="border bg-white border-gray-300 rounded-md px-3 py-2 w-full text-gray-600 text-sm"
              placeholder="Expectation on Income"
            />
            {instructorSignup.income === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>
          <div className="mt-0">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Category
            </label>
            <div className="relative">
              <select
                name="category"
                value={instructorSignup.category}
                onChange={handleChange}
                className="block appearance-none border bg-white border-gray-300 rounded-md px-3 py-2 w-full focus:outline-0 text-gray-600 text-sm"
              >
                <option
                  value=""
                  disabled
                  selected
                  className="text-gray-600 text-sm"
                >
                  Select your Category
                </option>
                {categoryData?.map((category) => (
                  <option
                    key={category?.id}
                    value={category?.id}
                    selected={instructorSignup?.category === category?.id}
                    className="text-gray-600 text-sm cursor-pointer"
                  >
                    {category?.cat_name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <img
                  src={process.env.PUBLIC_URL + `/images/Chevron Down.png`}
                  alt="drop-image"
                />
              </div>
            </div>
            {instructorSignup.category === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>
          {/* <div className="mt-0">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Subject Specialization
            </label>
            <div className="relative">
              <select
                onMouseDown={handleEvent}
                name="specialization"
                value={instructorSignup.specialization}
                onChange={handleChange}
                className="block appearance-none border bg-white border-gray-300 rounded-md px-3 py-2 w-full focus:outline-0 text-gray-600 text-sm"
              >
                <option
                  value=""
                  disabled
                  selected
                  className="text-gray-600 text-sm"
                >
                  Select your Specialization
                </option>
                {categoryData?.map((specality) => (
                  <option
                    key={specality?.id}
                    value={specality?.id}
                    selected={
                      specality?.id === instructorSignup?.specialization
                    }
                    className="text-gray-600 text-sm"
                  >
                    {specality?.sub_cat_name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <img
                  src={process.env.PUBLIC_URL + `/images/Chevron Down.png`}
                  alt="drop-image"
                />
              </div>
            </div>
            {instructorSignup?.specialization === "" && (
              <span className="block text-sm text-red-500 pt-1">
                {showErr ? "First Select Category" : "This Field is Required !"}
              </span>
            )}
          </div> */}

          <div className="mt-0">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              value={instructorSignup.subject}
              onChange={handleChange}
              className="border bg-white border-gray-300 rounded-md px-3 py-2 w-full text-gray-600 text-sm"
              placeholder="Subject"
            />
            {instructorSignup.subject === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>
          <div className="mb-0 mt-0 relative">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Upload ID Proof
            </label>
            <input
              type="file"
              name="idProof"
              accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"
              onChange={handleChange}
              className="relative border bg-white border-gray-300 text-gray-600 text-sm rounded-md px-3 py-2 w-full"
              placeholder="Upload Id proof here"
            />
            {instructorSignup.idProof === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
            {instructorSignup.idProofPreviewUrl && (
              <div className="mt-2">
                <img
                  src={instructorSignup.idProofPreviewUrl}
                  alt="ID Proof Preview"
                  className="w-32 h-32 object-cover"
                />
              </div>
            )}
          </div>
          <div className="mb-0 mt-0 relative">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Upload Educational Docs
            </label>
            <input
              type="file"
              accept=".jpg, .jpeg, .png,  .pdf, .doc, .docx"
              name="educationalDocs"
              onChange={handleChange}
              className="border bg-white border-gray-300 rounded-md px-3 py-2 w-full text-gray-600 text-sm"
              placeholder="Upload Documents"
            />
            {instructorSignup.educationalDocs === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
            {instructorSignup.educationalDocsPreviewUrl && (
              <div className="mt-2">
                <img
                  src={instructorSignup.educationalDocsPreviewUrl}
                  alt="Educational Docs Preview"
                  className="w-32 h-32 object-cover"
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-start mt-5">
          <input
            type="checkbox"
            name="agree"
            value={instructorSignup.agree}
            onChange={handleChange}
          />
          <p className="text-gray-600 text-[12px] pl-2">
            I agree to the TeacherCool{" "}
            <span className="text-blue-600 text-[12px]">
              Privacy policies & Terms
            </span>
          </p>
        </div>

        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600  text-white font-semi py-2 w-96 px-4 mt-7 rounded"
          >
            Join for Free
          </button>
        </div>
      </form>
    </div>
  );
};

export default FinalInstructor;
