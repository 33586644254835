import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { verifyAccountApi } from '../../store/authApi';

const EmailVerificationUI = () => {
    const { uid, id } = useParams();
    const[isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();
    const OnHandleVerifyEmail =  async()=>{
        await verifyAccountApi(uid, id).then((res) => {
            if (res.success) {
                //toast.success(res.message)
                setIsVerified(true)
            }
        }).catch((err) => {
            toast.error(err.message)
        })
    }
    
    const onVerify = () =>{
      navigate("/");
    }
  return (
    <div>
        <div class="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-500 via-blue-600 to-indigo-700 overflow-hidden relative">
  
  <div class="absolute top-10 left-10 w-60 h-60 bg-purple-300 opacity-20 rounded-full blur-3xl animate-pulse"></div>
  <div class="absolute bottom-10 right-10 w-72 h-72 bg-pink-300 opacity-30 rounded-full blur-3xl animate-pulse delay-1000"></div>
  
 
  <div class="relative bg-white rounded-2xl shadow-2xl p-10 max-w-md w-full text-center transform transition duration-500 hover:scale-105 hover:shadow-2xl animate-fadeInDown">
  
    <div class="relative flex items-center justify-center w-24 h-24 rounded-full bg-gradient-to-tr from-green-400 to-green-600 mx-auto mb-8 shadow-lg animate-bounce-slow">
    
    {!isVerified ? 
       <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-12 h-12 text-yellow-500 animate-spin-slow"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
       
        <circle cx="12" cy="12" r="10" class="stroke-yellow-500 fill-yellow-100"></circle>
        
       
        <line x1="12" y1="8" x2="12" y2="12" class="stroke-yellow-700"></line>
        <line x1="12" y1="12" x2="15" y2="15" class="stroke-yellow-700"></line>
        
      
        <path
          d="M9 7h6M9 17h6M9 7c0 1.5 1.5 3 3 3s3-1.5 3-3M9 17c0-1.5 1.5-3 3-3s3 1.5 3 3"
          class="stroke-yellow-700"
        ></path>
      </svg> : 
      <svg class="w-12 h-12 text-white" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-10.707a1 1 0 10-1.414-1.414L9 9.586 7.707 8.293a1 1 0 10-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg> }
    
       
      
      <div class="absolute -top-2 -right-2 w-4 h-4 bg-yellow-400 rounded-full animate-ping"></div>
    </div>

   
    {!isVerified ? <h2 class="text-3xl font-extrabold text-gray-800 mb-2 animate-fadeIn">Verification Pending !</h2> :
    <h2 class="text-3xl font-extrabold text-gray-800 mb-2 animate-fadeIn">Congratulations!</h2>} 
   {!isVerified ? <p class="text-gray-500 mb-8 animate-fadeIn delay-300">Your account verification is still in progress. Please wait a few moments!</p> :
   <p class="text-gray-500 mb-8 animate-fadeIn delay-300">Your account has been successfully verified. Explore and enjoy all features!</p>}

    
{!isVerified ? <button onClick= {()=>OnHandleVerifyEmail()} class="bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold px-8 py-3 rounded-lg shadow-lg transition transform duration-300 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-400 animate-bounce-in">
      Verify
    </button>
    :
    <button onClick= {()=>onVerify()} class="bg-gradient-to-r from-green-500 to-indigo-600 text-white font-semibold px-8 py-3 rounded-lg shadow-lg transition transform duration-300 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-400 animate-bounce-in">
      Go To Home
    </button>}
  </div>
</div>

    </div>
  )
}

export default EmailVerificationUI