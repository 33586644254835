import React, { useEffect, useState, useCallback } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../route/RoutePath";
import Logo from "../../../assests/images/Logo.png";
import { useSelector, useDispatch } from "react-redux";
// import { changeShowLogin } from "./store/features/linkedinLoginSlice";
import { changeShowLogin } from "../../../store/features/linkedinLoginSlice";
import {
  getCategories,
  getCourseListDashboard,
  getCoursesByCategory,
} from "../../../store/authApi";
import { logoutStudentAsync } from "../../../store/features/AuthSlice";
import { FaSearch, FaUserCircle } from "react-icons/fa";
import navbarImage from '../../../assests/images/navbarimage2.jpg'
// import Login from "../login/Login";

const Header = () => {
  const defaultBanner = "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
  const [activeIndex, setActiveIndex] = useState(0);
  const [courseData, setCourseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [getCategoryData, setGetCategoryData] = useState([]);
  const [getCategoryWiseCourses, setGetCategoryWiseCourses] = useState([]);
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const navigate = useNavigate();
  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    handleDropdownToggle()
    
  };

  let hoverTimeout;
  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => setIsHovered(false), 200); // Small delay to prevent quick hide
  };

  useEffect(() => {
    setLoading(true);
    getCategories()
      .then((res) => {
        if (res) {
          console.log(res.results);
          setGetCategoryData(res?.results);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  }, []);
  // Fetch courses from API

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    
    getCourseListDashboard()
      .then((res) => {
        if (isMounted && res) {
          setCourseData(res?.results || []);
        }
      })
      .catch((error) => {
        console.error('Error fetching courses:', error);
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  // Update filtered courses when searchTerm changes
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCourses([]); // Clear results if input is empty
    } else {
      const lowercasedTerm = searchTerm.toLowerCase();
      setFilteredCourses(
        courseData.filter((item) =>
          item?.title?.toLowerCase().includes(lowercasedTerm)
        )
      );
    }
  }, [searchTerm, courseData, isDropdownOpen]);

  const dispatch = useDispatch();

  

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const navigateToDashboard = () => {
    const user_type = localStorage.getItem("user_type");
    console.log(user_type, "userType");

    //  if(user_type==="student"){
    //   navigate
    //  }
  };
  const handleMouseHover = (id) => {
    setLoading(true);
    getCoursesByCategory()
      .then((res) => {
        if (res?.success && res?.data) {
          const categoryData = res.data[id];
          setGetCategoryWiseCourses(categoryData?.courses || []);
        } else {
          console.warn(`No data found for category ID: ${id}`);
          setGetCategoryWiseCourses([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching category courses:', error);
        setGetCategoryWiseCourses([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log(getCategoryWiseCourses, "getCategoryWiseCourses");

  const onClickNavigate = (id) =>{
    localStorage.setItem("courseId", id)
    handleDropdownToggle()
  }

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.trim() === "") {
        setFilteredCourses([]);
      } else {
        const lowercasedTerm = searchValue.toLowerCase();
        setFilteredCourses(
          courseData.filter((item) =>
            item?.title?.toLowerCase().includes(lowercasedTerm)
          )
        );
      }
    }, 300),
    [courseData]
  );

  return (
    <header className="bg-white shadow-md sticky top-0 z-50 max-sm:p-4 sm:p-4 lg:p-0">
      {/* <div className="max-w-screen-2xl mx-20 px- sm:px-6 lg:px-8 flex items-center justify-between h-16 bg-red-500"> */}
      <div className="w-full  px- sm:px-6 lg:px-8 flex items-center justify-between h-16">
        {/* Left Section */}
        <div className="flex items-center">
          <NavLink to={RoutePaths?.home?.path}>
            <img src={Logo} alt="nav-logo" className="cursor-pointer" />
          </NavLink>
        </div>

        {/* Center Section */}
        <nav className="hidden md:flex space-x-8 relative">
          {/* Explore Programs with dropdown */}
          <div className="relative group">
            <button
            onMouseEnter={()=>setIsDropdownOpen(true)}
              className="
                flex 
                items-center 
                rounded 
                px-4 
                py-2 
                font-medium 
                bg-gradient-to-r 
                from-[#19201a] 
                to-[#5b574a] 
                text-white 
                hover:shadow-lg 
                hover:from-[#313432] 
                hover:to-[#3e3a2b] 
                transition 
                duration-300 
                ease-in-out 
                focus:outline-none 
                focus:ring-2 
                focus:ring-offset-2 
                focus:ring-blue-600
              "
            >
              Explore
              <span
                className="  text-xs
                  ml-1 
                  transition-transform 
                  transform 
                  group-hover:rotate-180
                "
              >
                ▼
              </span>
            </button>
            
           {isDropdownOpen &&
           <div className="absolute  border shadow-xl left-0 z-[500] top-full w-screen max-w-6xl bg-white border-t  hidden group-hover:block">
           <div className="w-full  pl-4 py-4 grid grid-cols-12 ">
             <div className="col-span-3">
               <h3 className="font-semibold text-gray-700 underline">
                 Courses Category
               </h3>
               {getCategoryData?.map((item, index) => (
                 <ul key={index}>
                   <li
                     className="py-2 hover:bg-gray-100"
                     onMouseEnter={() => handleMouseHover(item?.id)} // Log course ID on hover
                   >
                     <NavLink
                       onClick={() => handleItemClick(item.id)}
                       key={index}
                       to={{
                         pathname: "/courses",
                         search: `?query=${item.cat_name}`,
                       }}
                       state={{ id: item.id }}
                       className="cursor-pointer  text-black rounded-sm flex justify-between items-start"
                     >
                       <div className="">{item.cat_name}</div>
                     </NavLink>
                     {/* <Link to="/program1">{item?.cat_name}</Link> */}
                   </li>
                 </ul>
               ))}
             </div>

             <div className="col-span-9">
               <div className="flex flex-row items-center pr-10 justify-between">
               <h3 className="font-semibold text-gray-700">Courses</h3>
               <NavLink to={{
          pathname: "/courses",
          search: "?query=free",
        }}  onClick={() => handleDropdownToggle()} className="font-semibold text-blue-500 cursor-pointer text-sm  underline">View All</NavLink>
               </div>
               <div class="grid grid-cols-1 h-96 overflow-y-scroll pr-4 pl-4  sm:grid-cols-1 md:grid-cols-2 gap-10">
                 {getCategoryWiseCourses?.map((item, index) => (
                   <div class="flex items-start h-auto ">
                     <img
                       class="w-16 h-16 mr-4"
                       src={item?.banner? item?.banner: defaultBanner}
                       alt="Sunset in the mountains"
                     />
                     <div class="flex flex-col justify-center">
                       <a
                         class="font-medium text-base  hover:text-indigo-600 transition duration-500 ease-in-out"
                       >
                         {item.title.length > 20 ? `${item.title.slice(0, 20)}...` : item.title}
                       </a>
                       <p class="text-gray-500 text-sm">
                         {item.description.length > 30
                           ? `${item.description.slice(0, 30)}...`
                           : item.description}
                       </p>
                       <NavLink onClick={() => onClickNavigate(item?.id)} to={`/courses/${item?.id}/${item?.title}`} className="text-blue-500">
                   Learn more <span>&raquo;</span>
                 </NavLink>
                     </div>
                   </div>
                 ))}
               </div>
             </div>

           </div>
           <div className=" ">
              <img className="  pr-2 pl-2 w-full object-contain" src={navbarImage} alt="Sunset in the mountains" />
              </div>
         </div> } 
           
          </div>

          {/* Search Box */}
          <div className="relative">
            {/* Search Input */}
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debouncedSearch(e.target.value);
              }}
              placeholder="Search..."
              className="bg-gray-100 rounded-full px-8 py-2 border-2 border-gradient-to-r from-blue-700 to-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
              style={{ width: "400px" }}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M16.5 10.5a6 6 0 11-12 0 6 6 0 0112 0z"
                />
              </svg>
            </span>

            {/* Search Results */}
            {searchTerm && (
              <div className="absolute mt-2 bg-white border rounded shadow-lg w-full max-h-60 overflow-y-auto">
                {filteredCourses.length > 0 ? (
                  filteredCourses.map((course, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {course?.title}
                    </div>
                  ))
                ) : (
                  <div className="px-4 py-2 text-gray-500">
                    No results found
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Other navigation links */}
          <Link
            to="/careers"
            className="text-gray-700 font-medium hover:text-blue-600 mt-2"
          >
            Careers
          </Link>

          <Link
            to="/contactus"
            className="text-gray-700 font-medium hover:text-blue-600 mt-2"
          >
            Contact Us
          </Link>
          <Link
            to="/about"
            className="text-gray-700 font-medium hover:text-blue-600 mt-2"
          >
            About Us
          </Link>
          {/* <div className="relative group">
            <button className="text-gray-700 font-medium hover:text-blue-600 focus:outline-none">
              More
            </button>
            <div className="absolute hidden group-hover:block bg-white border rounded shadow-lg w-48">
              <ul>
                <li className="px-4 py-2 hover:bg-gray-100">
                  <Link to="/option1">Option 1</Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100">
                  <Link to="/option2">Option 2</Link>
                </li>
              </ul>
            </div>
          </div> */}
        </nav>

        {/* Right Section */}

        {localStorage.getItem("userAuth") ? (
          <div
            className="user-profile"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* Profile Icon and Name */}
            <div className="profile-icon-name">
              <FaUserCircle className="profile-icon" size={24} />
              <span className="user-name">
                {localStorage.getItem("userName").replace(/"/g, "")}
              </span>
            </div>

            {/* Popup Menu on Hover */}
            {isHovered && (
              <div
                className="popup-menu"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <ul>
                  <li>
                    <NavLink
                      to={
                        localStorage.getItem("user_type").includes("student")
                          ? "/student/dashboard"
                          : localStorage
                              .getItem("user_type")
                              .includes("corporate")
                          ? "/corporate/dashboard"
                          : "/teacher/dashboard"
                      }
                    >
                      DashBoard
                    </NavLink>
                  </li>

                  <li>
                    <button
                      onClick={() => dispatch(logoutStudentAsync(navigate))}
                      className="logout-btn"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <div className=" flex gap-4">
            <div
              onClick={() => dispatch(changeShowLogin(true))}
              className="text-blue-600 cursor-pointer font-medium hover:bg-blue-100 border border-blue-600 px-4 py-2 rounded"
            >
              LOGIN
            </div>

            <div
              onClick={() => dispatch(changeShowLogin(true))}
              className="text-blue-600 cursor-pointer font-medium hover:bg-blue-100 border border-blue-600 px-4 py-2 rounded"
            >
              Join For Free
            </div>
          </div>
        )}
      </div>

      <div className="h-8 flex items-center justify-center space-x-12 font-bold text-lg bg-gradient-to-r from-purple-500 to-[#f1c40f] shadow-lg">
        <div className="hover:text-white cursor-pointer">
          <NavLink
            className={`${
              // activeIndex === 0
              pathName[1] === "forIndividual"
                ? "active no-underline pb-2 border-b-2 border-solid border-blue-900 cursor-pointer h-7 text-white"
                : "cursor-pointer no-underline  flex-shrink-0 h-7 text-[#000]"
            }`}
            // onClick={() => handleItemClick(0)}
            to={"/forIndividual"}
          >
            For Individuals
          </NavLink>
        </div>
        <div className=" hover:text-white cursor-pointer">
          <NavLink
            className={`${
              // activeIndex === 1
              pathName[1] === "universities"
                ? "active no-underline text-white pb-2 border-b-2 border-solid border-blue-900 cursor-pointer h-7"
                : "cursor-pointer no-underline text-[#000] flex-shrink-0 h-7"
            }`}
            onClick={() => handleItemClick(1)}
            to={RoutePaths.forUniversities.path}
          >
            For Universities
          </NavLink>
        </div>
        <div className=" hover:text-white cursor-pointer">
          <NavLink
            className={`${
              // activeIndex === 2
              pathName[1] === "corporate"
                ? "active no-underline text-white pb-2 border-b-2 border-solid border-blue-900 cursor-pointer h-7"
                : "cursor-pointer no-underline text-[#000] flex-shrink-0 h-7"
            }`}
            onClick={() => handleItemClick(2)}
            to={RoutePaths?.forCorporates?.path}
          >
            For Corporates
          </NavLink>
        </div>
      </div>
      
    </header>
  );
};

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default Header;
