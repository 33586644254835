import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { getCompleteUrl } from "../../utils/util";

const CardTwo = (props) => {
  const dummyImage = 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png';

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-5 max-sm:m-1">
        {props?.data
          ?.filter((item) => item.course_count > 0) 
          .map((item, ind) => (
            <NavLink
              to={`/courses/${item.id}`}
              state={{ id: item.id }}
              key={ind}
              className="mt-4 cursor-pointer border-solid min-h-16 border border-gray-200 hover:shadow-md rounded-lg"
            >
              <img
                className="w-full xl:h-36 lg::h-24 md:h-24 h-20 rounded-t-lg object-fill"
                src={item?.banner ? getCompleteUrl(item.banner) : dummyImage}
                alt="card-image"
              />
              <div className="py-1 pl-3">
                <h2 className="font-semibold text-sm text-gray-900 pt-3">
                  {item?.cat_name}
                </h2>
                <p className="text-sm pt-1 text-gray-600 pr-1">
                  {item?.course_count} courses
                </p>
              </div>
            </NavLink>
          ))}
      </div>
    </>
  );
};


export default CardTwo;
