import React from "react";

const ManageCertificates = () => {
  return (

    <div className="grid grid-cols-1 md:grid-cols-12 my-16">
      <div className="md:col-span-8 col-span-1 px-4">

        <h1 className="font-medium font-Roboto text-[32px] text-[#1E1E1E] pb-2">
          Earn a career certificate
        </h1>
        <p className="font-Roboto text-base text-[#1E1E1E] text-opacity-70">
          Add this credential to your LinkedIn profile, resume, or CV. Share it
          on social media and in your performance review.
        </p>
        <button className="font-Roboto font-medium text-base text-[#0966ED] border w-[184px] h-[42px] my-6 rounded-md border-[#0966ED]">
          APPLY NOW
        </button>
      </div>

      <div className="md:col-span-4 col-span-1 flex justify-center">

        <img
          src={process.env.PUBLIC_URL + "/images/student/certificates.png"}
          alt="certificate"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
};

export default ManageCertificates;
