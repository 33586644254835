import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../../assests/images/Logo.png'
const Footer = () => {
  const footerData = [
    "About",
    "Universities & Corporates",
    "Courses We Offer",
    "Terms and Conditions",
    "Privacy Policy",
  ];
  return (

    // <footer className="bg-[#0A0E13] text-gray-400 py-12 px-8 md:px-20 lg:px-32">
    <footer className="bg-blue-950 text-gray-400 py-12 px-8 md:px-20 lg:px-32">
      <div className="flex flex-col md:flex-row justify-between gap-10">
        <div className="flex flex-col gap-4 max-w-xs">
          <div className="text-indigo-500 text-3xl font-bold">
            <img src={logo} alt=""/>
          </div>
          <p className="text-sm">
            Empowering learners worldwide by delivering exceptional educational experiences.
          </p>
          <div className="flex gap-4 text-xl">
            <NavLink to="https://www.facebook.com/people/Teacher-Cool/61556772354177/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
              <i className="fab fa-facebook-f"></i>
            </NavLink>
            <NavLink to="https://www.instagram.com/teachercool_official/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
              <i className="fab fa-instagram"></i>
            </NavLink>
            <NavLink to="https://x.com/TeacherCoo81249" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
                  <i className="fab fa-x-twitter"></i>
                </NavLink>
            <NavLink to="https://www.linkedin.com/in/teachercool/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
              <i className="fab fa-linkedin-in"></i>
            </NavLink>
            <NavLink to="https://bsky.app/profile/teachercool.bsky.social" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
              <i className="fab fa-bisk"></i>
            </NavLink>

          </div>
        </div>

        {/* Links Section */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-sm">
          <div>
            <h4 className="font-semibold text-gray-200">Solutions</h4>
            <ul className="mt-2 space-y-2">
              <li className="cursor-pointer px-2 hover:text-white hover:underline">
                <NavLink to="/about/" onClick={() => window.scrollTo(0, 0)}>About</NavLink>
              </li>

              <li className="cursor-pointer px-2  hover:text-white hover:underline">
                <NavLink to="/whatweoffer/" onClick={() => window.scrollTo(0, 0)}>What We Offer</NavLink>
              </li>

              <li className="cursor-pointer px-2 hover:text-white hover:underline">
                <NavLink to="/corporate/" onClick={() => window.scrollTo(0, 0)}>For Corporates</NavLink>
              </li>

              <li className="cursor-pointer px-2  hover:text-white hover:underline">
                <NavLink to="/universities/" onClick={() => window.scrollTo(0, 0)}>For Universities</NavLink>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold text-gray-200">Support</h4>
            <ul className="mt-2 space-y-2">
              <li><NavLink to="#" className="hover:underline hover:text-white">Blogs</NavLink></li>
              <li><NavLink to="#" className="hover:underline hover:text-white">Artilces</NavLink></li>
              {/* <li><NavLink to="#" className="hover:underline hover:text-white">Guides</NavLink></li> */}
            </ul>
          </div>
          <div>
            <h4 className="font-semibold text-gray-200">Company</h4>
            <ul className="mt-2 space-y-2">
              <li><NavLink to="/terms"  onClick={() => window.scrollTo(0, 0)} className="hover:underline hover:text-white">Terms</NavLink></li>
              <li><NavLink to="/policy"  onClick={() => window.scrollTo(0, 0)} className="hover:underline hover:text-white">Privacy</NavLink></li>
              <li><NavLink to="/contactus"  onClick={() => window.scrollTo(0, 0)} className="hover:underline hover:text-white">Contact Us</NavLink></li>
              <li><NavLink to="/aboutus"  onClick={() => window.scrollTo(0, 0)} className="hover:underline hover:text-white">About Us</NavLink></li>
            </ul>
          </div>

          {/* <div>
            <h4 className="font-semibold text-gray-200">Legal</h4>
            <ul className="mt-2 space-y-2">
              <li><a href="#" className="hover:underline">Terms of service</a></li>
              <li><a href="#" className="hover:underline">Privacy policy</a></li>
              <li><a href="#" className="hover:underline">License</a></li>
            </ul>
          </div> */}

        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-10 border-t border-gray-700 pt-6 text-center text-sm font-Poppins text-white">
        <div className="font-Poppins">
          Address: B-99, 5th Floor, Phase-I Panchsheel Park Malviya Nagar, Near Triveni Complex New Delhi - 110017
        </div>
        © {new Date().getFullYear()} TeacherCool inc. All rights reserved. The Certification names are the trademarks of their respective owners.
      </div>
    </footer>
  )
}



export default Footer;
