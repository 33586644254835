import React, { useEffect, useState } from "react";
import pic from "../../../src/assests/images/aboutImage.png";
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import StudentCatButtons from "./StudentCatButtons";
import Footer from "../../components/Footer/Footer";
import internshipProgram from "../../assests/images/brandings/Internshipprogram.jpeg";
import javascriptBanner from "../../assests/images/brandings/JavaScriptBanner.jpg";
import {
  getCategories,
  getCourseListDashboard,
  getCourseProgressByStudentId,
  getEnrollement,
} from "../../store/authApi";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../admin/customComponent/ProgressBar";
import { Rate } from "antd";
import InternshipProgram from "../../components/InternshipProgram";

function StudentHomePage() {
  const bannerImage =
    "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
  const [showAll, setShowAll] = React.useState(false);
  const navigate = useNavigate();
  const [catId, setCatId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const [myInrolementCourse, setMyInrolementCourse] = useState([]);
  const [textVlaue, setTextValue] = useState(25);


  const [mycourseData, setMyCourseData] = useState([]);


  const [studentId, setStudentId] = useState(localStorage.getItem("userId"));
  useEffect(() => {
    const fetchCourseData = async () => {
      if (studentId) {
        setLoading(true);
        try {
          const res = await getCourseProgressByStudentId(studentId);
          console.log("res", res);
          setMyCourseData(res?.data || []);
          
        } catch (error) {
          console.error("Error fetching course data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCourseData();
  }, [studentId]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setLoading(true);
    getCourseListDashboard()
      .then((res) => {
        if (res) {
          setCourseData(res.results);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));

    getEnrollement(userId)
      .then((res) => {
        if (res) {
          // console.log("++++++++++++++",res)
          setMyInrolementCourse(res.data);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  }, []);

  console.log("my enrolement", myInrolementCourse);
  useEffect(() => {
    getCategories().then((res) => setData(res?.results));
  }, []);
  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return (
        <>
          {words.slice(0, wordLimit).join(" ")}...{" "}
          <span onClick={() => setTextValue(100000)} style={{ color: "red" }}>
            read more
          </span>
        </>
      );
    }
    return text;
  }

  return (
    <>
      <div className="w-full px-4 md:px-8 lg:px-16 py-8">
        <h2 className="text-xl underline font-semibold mb-4">My Learning</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {mycourseData?.map((item, ind) => (
            <div
              key={ind}
              className="border border-[#E2E8F0] rounded-md flex flex-col px-2 py-2 my-1 cursor-pointer"
              onClick={() => {
                navigate("/student/learn/course", { state: { item: item } });
                // window.location.reload();
              }}
            >
              <motion.div className="hover:scale-110 hover:shadow-md transition duration-1000">
                <div className="px-1 flex items-center justify-center py-1">
                  <img
                     src={item?.course?.banner ? `${process.env.REACT_APP_BASEURL}${item?.course?.banner}` : bannerImage}
                    // src={`${process.env.REACT_APP_BASEURL}${item?.course?.banner}`}
                    alt="courses"
                    className="w-full h-[10rem] object-cover"
                  />
                </div>
              </motion.div>
              <span className="text-lg font-Roboto truncate font-semibold text-[#1E1E1E] text-opacity-80 pt-2 pb-1">
                {item?.course?.title}
              </span>
              <span className="text-xs font-Roboto text-[#1E1E1E] text-opacity-80 pb-1">
                {truncateText(item?.course?.description, textVlaue)}
              </span>
              <ProgressBar
                      bgcolor="#0966ED"
                      progress={item?.progress > 100 ? 100 : item?.progress}
                      width={"full"}
                      height={7}
                    />
              <div className="flex gap-1 items-center pb-2">
                <Rate allowHalf defaultValue={4} />
                <span className="font-Roboto text-xs text-[#1E1E1E] text-opacity-80">{`(${1200} Ratings)`}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full px-4 md:px-8 lg:px-16 py-8 flex">
        <div className="w-3/4">
          <h2 className="text-xl font-semibold mb-4">
            Explore with a {data?.[0]?.cat_name}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {courseData
              ?.filter((card) => card.category === data[0]?.id)
              ?.map((card, index) => (
                <Card
                  key={index}
                  img={card.banner}
                  institution={card.skills}
                  title={card.title}
                  type={card.mode}
                  level={card.level}
                  id={card.id}
                />
              ))}
          </div>
        </div>
        <div className="w-1/2">
          <img
            src={javascriptBanner}
            alt="Description"
            className="w-full h-auto"
          />
        </div>
      </div>

      {/* section 3  */}
      <div className="w-full px-4 md:px-8 lg:px-16 py-8">
        <h2 className="text-xl font-semibold mb-4">
          Explore with a {data?.[2]?.cat_name}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {courseData
            ?.filter((card) => card.category === data[2]?.id) // Filter based on data[0]?.id
            ?.map((card, index) => (
              <Card
                key={index}
                img={card.banner}
                institution={card.skills}
                title={card.title}
                type={card.mode}
                level={card.level}
                id={card.id}
              />
            ))}
        </div>
        {/* {!showAll && (
          <button
            onClick={() => setShowAll(true)}
            className="mt-4 text-blue-500 hover:underline"
          >
            Show 8 more
          </button>
        )} */}
      </div>

      <StudentCatButtons data={data} />

      {/* <Footer/> */}
    </>

    //     <>
    //   <AppHeader />
    //   <div className="flex">

    //     <div className="w-1/4">
    //       <AppSidebar />
    //     </div>

    //     {/* Main content on the right */}
    //     <div className="w-3/4 px-4 md:px-8 lg:px-16 py-8">
    //       <h2 className="text-xl font-semibold mb-4">Based on Your Recent Views</h2>
    //       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
    //         {displayedCards.map((card, index) => (
    //           <Card
    //             key={index}
    //             img={card.img}
    //             institution={card.institution}
    //             title={card.title}
    //             type={card.type}
    //           />
    //         ))}
    //       </div>
    //       {!showAll && (
    //         <button
    //           onClick={() => setShowAll(true)}
    //           className="mt-4 text-blue-500 hover:underline"
    //         >
    //           Show 8 more
    //         </button>
    //       )}
    //     </div>

    //   </div>
    // </>
  );
}

export default StudentHomePage;

//card
const Card = ({ img, institution, title, type, level, id }) => {
  console.log("img", institution);
  const defaultBanner =
    "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
  return (
    <NavLink
      onClick={() => localStorage.setItem("courseId", id)}
      to={`/courses/${id}/${title}`}
      className="border relative border-gray-200 rounded-lg shadow-md overflow-hidden"
    >
      <img
        className="w-full h-32 object-cover"
        src={img ? img : defaultBanner}
        alt={title}
      />
      <div className="p-4">
        <span className="text-xs truncate text-gray-500">
          {Array.isArray(institution)
            ? institution.map((skill, index) => (
                <span key={index}>
                  {skill}
                  {index !== institution.length - 1 && ", "}
                </span>
              ))
            : institution?.split(",").slice(0, 3)}
        </span>
        <h3 className="text-sm truncate font-semibold text-gray-900 mt-1">
          {title}
        </h3>
        <p className="text-xs text-gray-600">{type}</p>
      </div>
      <span
        className={`absolute top-2 left-2 text-white text-xs font-bold py-1 px-3 rounded-full shadow-md bg-blue-600`}
      >
        {level}
      </span>
    </NavLink>
  );
};

export { Card };
