import axios from "axios";
import apiClientRefresh from "./refreshToken";

const apiClient = () => {
  const token = localStorage.getItem("userAuth");

  // Check if the token is expired
  if (token && isTokenExpired(token)) {
    apiClientRefresh();
    // Optionally refresh token here, or logout
    // For example:
    // await refreshToken();
    // Update config with the new token
  }
};

export default apiClient;

// Utility function to check if token is expired
const isTokenExpired = (token) => {
  const expiry = JSON.parse(atob(token.split(".")[1])).exp; // Decode JWT
  return Date.now() >= expiry * 1000; // Check if expired
};
