import React from "react";

const Skillgain = ({ courseSkills }) => {
  console.log(courseSkills); // This should log an array of skills.
  return (
    <div className="pt-10 pb-6 mx-auto w-full max-w-4xl">
      <p className="font-Roboto font-medium text-lg text-center">
        Skills you'll gain
      </p>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
        {courseSkills?.skills?.length > 0 ? (
          courseSkills?.skills?.map((item, index) => (
            <p
              key={index}
              className="bg-[#F4F6FC] text-xs text-[#0966ED] font-Roboto rounded-full px-3 py-2 text-center"
            >
              {item.trim()} {/* Trim any extra whitespace */}
            </p>
          ))
        ) : (
          <p className="text-center col-span-full">No skills available</p>
        )}
      </div>
    </div>
  );
};

export default Skillgain;
