import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { studentRegisterAsync } from "../../../../store/features/AuthSlice";
import { Select } from "antd";
import { FiEdit2 } from "react-icons/fi";
import { toast } from "react-toastify";
import { typeofCountryAsync } from "../../../../store/features/AuthSlice";

const UniversityFinalPage = (props) => {
  const {
    universityFormvalues,
    setUniversityFormValues,
    selectedOption,
    NavigateOnLogin,
  } = props;
  const onChange = (value) => {
    setUniversityFormValues((prevState) => ({
      ...prevState,
      country: value,
    }));
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const dispatch = useDispatch();
  const { userType, student, countrylist } = useSelector((state) => state.auth);
  const fileRef = useRef();
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    if (validator.isEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    }
  };

  useEffect(() => {
    dispatch(typeofCountryAsync());
  }, []);

  const userTypeId = userType.find((item) => item.name === selectedOption)?.id;

  const registerUniversity = async (event) => {
    event.preventDefault();
    const data = {
      type: userTypeId,
      name: universityFormvalues.universityName,
      email: universityFormvalues.universityEmail,
      mobile: universityFormvalues.universityNumber.slice(2, 12),
      password: universityFormvalues.universityPassword,
      confirm_password: universityFormvalues.universityPassword,
      image: universityFormvalues.image,
      country: universityFormvalues.country,
      university_details: {
        uni_name: universityFormvalues.whichUniversity,
        address: universityFormvalues.whichAddress,
        email: universityFormvalues.whichEmail,
      },
    };
    if (
      universityFormvalues.country !== "" &&
      universityFormvalues.image !== "" &&
      universityFormvalues.whichUniversity !== "" &&
      universityFormvalues.whichAddress !== "" &&
      universityFormvalues.whichEmail !== ""
    ) {
      dispatch(studentRegisterAsync(data));
      NavigateOnLogin();
    } else {
      toast.error("All fields are required!");
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setUniversityFormValues((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setUniversityFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === "whichEmail") {
      validateEmail(value);
    }
  };
  const handleButtonClick = () => {
    fileRef.current.click();
  };
  return (
    <div className="mt-6">
      <input
        type="file"
        accept="image/*"
        name="image"
        ref={fileRef}
        className="hidden"
        onChange={handleChange}
      />
      <form onSubmit={registerUniversity}>
        <div className="flex items-center justify-around gap-5">
          <div className="relative w-[159px] h-[156px] rounded-full border-2">
            <label onClick={handleButtonClick}>
              <p className="absolute z-10  right-3 m-2 bg-[white] cursor-pointer">
                <FiEdit2 className="text-gray-400 bg-[white]" />
              </p>
            </label>

            <div className="absolute text-sm text-gray-600 text-wrap text-center bottom-12">
              {universityFormvalues?.image ? (
                <img
                  src={universityFormvalues?.image}
                  className="relative top-12 left-0 border-none rounded-full w-[155px] h-[155px]"
                  alt="Profile"
                />
              ) : (
                " Image size should be 150x200"
              )}
            </div>
          </div>
          <div className="cursor-pointer">
            <p className="text-sm text-gray-900 font-semibold">
              Upload Picture
            </p>
            <span className="text-gray-600 text-sm">(Non Mandatory)</span>
          </div>
        </div>
        <div className="mb-4 mt-6">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Country
          </label>
          <Select
            showSearch
            className="w-full"
            // style={{ width: "300px" }}
            placeholder="Select a Country"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={((countrylist && countrylist) || []).map((d) => ({
              value: d.id,
              label: d.name,
            }))}
          />
          {universityFormvalues.country === "" && (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          )}
        </div>

        <div className="mb-4 mt-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            University Name
          </label>
          <input
            type="text"
            name="whichUniversity"
            value={universityFormvalues.whichUniversity}
            onChange={handleChange}
            className="border bg-[white] text-gray-600 text-sm border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="Enter university Name"
          />
          {universityFormvalues.whichUniversity === "" && (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            University Email
          </label>
          <input
            type="email"
            name="whichEmail"
            value={universityFormvalues.whichEmail}
            onChange={handleChange}
            className="border bg-[white] text-gray-600 text-sm border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="Enter university email"
          />
          {universityFormvalues.whichEmail === "" ? (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          ) : (
            <span
              className={`block text-sm  ${
                errors == "" ? "" : "text-red-600 pt-1"
              }`}
            >
              {errors.email}
            </span>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            University Address
          </label>
          <input
            type="text"
            name="whichAddress"
            value={universityFormvalues.whichAddress}
            onChange={handleChange}
            className="border bg-[white] text-gray-600 text-sm border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="Enter university Address"
          />
          {universityFormvalues.whichAddress === "" && (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          )}
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-5 mb-5 rounded"
        >
          Join for Free
        </button>
      </form>
    </div>
  );
};

export default UniversityFinalPage;
