import React from "react";
import LabCard from "./card/LabCard";
import { NavLink } from "react-router-dom";

const LearningLab = () => {
  return (
    <div className="my-24">
      <h1 className="text-2xl font-Roboto text-[#1E1E1E] font-semibold">
        Learning Labs
      </h1>
      <p className="text-sm font-Roboto text-[#1E1E1E] text-opacity-70 py-2">
        Explore our Learning Labs where innovation meets education. Engage in
        hands-on projects, collaborate with peers, and enhance your skills in a
        dynamic environment. Our labs are designed to foster creativity and
        critical thinking, ensuring you gain practical experience alongside
        theoretical knowledge. Join us to unlock your potential and take your
        learning journey to the next level!
      </p>
      <NavLink className="text-sm font-semibold font-Roboto text-[#0966ED] border-b-2 border-blue-500 ">
        Read more
      </NavLink>
      <div className="mt-10">
        <LabCard />
      </div>
    </div>
  );
};

export default LearningLab;
