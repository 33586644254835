import React from "react";
import { AboutLeft } from "./AboutLeft";
import { AboutRight } from "./AboutRight";
import img1 from "../../../../assests/images/Ellipse 4.png";

export const AboutUs = () => {
  return (
    <>
      <div className="container relative px-[7.25rem] max-sm:px-4 mx-auto grid lg:grid-cols-8 sm:grid-cols-1  max-sm:grid-cols-1 gap-10 mt-10 mb-5">
        <div className="col-span-3 sm:order-2 max-sm:order-2 xl:order-1  max-sm:col-span-1 ">
          <AboutLeft />
        </div>

        <div className="col-span-5 max-sm:col-span-1 sm:order-1 max-sm:order-1 xl:order-2 ">
          <AboutRight />
        </div>

        {/* <div className=" hidden md:block absolute  top-0 right-0 ">
          <img src={img1} alt="" />
        </div> */}
      </div>
    </>
  );
};
