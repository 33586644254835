import React from "react";
import RecomendCoursecard from "./card/RecomendCoursecard";

const RecommendedCourse = () => {
  return (
    <div>
      <div className="flex flex-wrap items-center gap-2">
        <h1 className="font-Inter font-semibold text-[32px]  text-[#1E1E1E] ">
          Recommended
        </h1>
        <h1 className="text-blue-500 font-Inter font-semibold text-[32px]  ">
          Courses
        </h1>
      </div>
      <RecomendCoursecard />
    </div>
  );
};

export default RecommendedCourse;
