import React from "react";
import { ExpertLeft } from "./ExpertLeft";
import { ExpertRight } from "./ExpertRight";
import expert from '../../assests/images/expertHelp.jpg'
import getExpert from '../../assests/images/getExpertHelp.jpg'
export const ExpertHelp = () => {
  return (
    <>
      <div className=" my-10 mx-auto px-[7.25rem] max-sm:px-4 grid lg:grid-cols-12 md:grid-cols-8 sm:grid-cols-1  max-sm:grid-cols-1 lg:gap-10 max-sm:lg:gap-2 mt-10"
      style={{backgroundImage:`url(${getExpert})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            backgroundPosition:'center',
            // backgroundAttachment:'fixed'
      }}>
        <div className="  md:col-span-6 bg-[#fbfbfb] border border-gray-300 rounded-lg bg-opacity-70 p-5 shadow-lg">
          <ExpertLeft />
          </div>
      </div>

     
    </>
  );
};
