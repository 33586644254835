import React, { useState, useEffect } from "react";
import JoinFreeBtn from "../button/PrimaryButton";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import useRazorpay from "../../razorpay/PaymentComponent";
import { fetchExchangeRate } from "../../store/authApi";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { changeShowLogin } from "../../store/features/linkedinLoginSlice";
import { useNavigate } from "react-router-dom";

const CardOne = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { displayRazorpay } = useRazorpay();
  const priceInINR = 100;
  const [userCurrency, setUserCurrency] = useState("INR"); // Default currency INR
  const [conversionRate, setConversionRate] = useState();
  const [orderDeatails, setOrderDetails] = useState({
    razorpay_order_id: uuidv4(),
    amount: "1",
    currency: "INR",
    receipt: uuidv4(),
    status: "Completed",
    other_info: {},
  });

  // Function to fetch the user's currency and conversion rate
  useEffect(() => {
    fetchExchangeRate(userCurrency).then((rate) => {
      setConversionRate(rate);
    });
  }, [priceInINR]);

  const handlePayment = (id) => {
    localStorage.setItem("courseId", id);
    displayRazorpay(orderDeatails, id);
  };

  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }



  return (
<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-5 max-sm:m-6">
  {props?.dataOne?.map((item, index) => {
    const defaultBanner = "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";

    return (
      <React.Fragment key={index}>
        <div className="relative mt-4 border-solid border border-gray-200 hover:shadow-md rounded-lg overflow-hidden h-auto flex flex-col">
          {/* Badge */}
          <span
            className={`absolute top-2 left-2 text-white text-xs font-bold py-1 px-3 rounded-full shadow-md ${
              item?.level === "beginner"
                ? "bg-green-500"
                : item?.level === "intermediate"
                ? "bg-yellow-500"
                : "bg-red-500"
            }`}
          >
            {item?.level}
          </span>

          {/* Image */}
          <img
            className="w-full h-[10rem] rounded-t-lg object-cover"
            src={item?.banner ? item?.banner : defaultBanner}
            alt="card-image"
          />

          <div className="p-3 ">
            <h2 className="font-semibold h-6 overflow-hidden text-sm text-gray-900">{item?.title}</h2>
            <p numberOfLines={2} className="text-sm pt-1  h-16 overflow-hidden  text-gray-600 pr-1">
              {truncateText(item?.description, 10)}{" "}
              <NavLink
                onClick={() => localStorage.setItem("courseId", item?.id)}
                to={`/courses/${item.id}/${item.title}`}
                className="text-blue-500"
              >
                Learn more <span>&raquo;</span>
              </NavLink>
            </p>

            <div className="pb-2 flex flex-col gap-1">
              <div className="flex items-center text-sm text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-blue-500 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.086.31-.197.613-.332.907M15 12a3 3 0 11-6 0 3 3 0z"
                  />
                </svg>
                <span className="ml-1">17k views</span>
              </div>

              <div className="flex items-center text-sm text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-green-500 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c1.5 0 3-.667 3-1.5S13.5 5 12 5 9 5.667 9 6.5s1.5 1.5 3 1.5zm0 0c-1.5 0-3 .667-3 1.5s1.5 1.5 3 1.5 3-.667 3-1.5-1.5-1.5-3-1.5zm0 0V5m0 4v10m6 2H6"
                  />
                </svg>
                <span className="font-bold text-gray-800">
                  Price: {(item.price * conversionRate).toFixed(2)} {userCurrency}
                </span>
              </div>
            </div>
          </div>

          {/* Button Container */}
          <div className="flex justify-between items-center p-3 space-x-2">
            {/* Buy Now Button */}
            <div
              onClick={() => {
                if (localStorage.getItem("userAuth")) {
                  handlePayment(item?.id);
                } else {
                  dispatch(changeShowLogin(true));
                }
              }}
              className="text-xs bg-[#0966ED] text-center text-white font-bold py-2 px-4 rounded-md shadow-lg hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out cursor-pointer flex-1"
            >
              Buy Now
            </div>

            {/* Try for Free Button */}
            <div
              onClick={() => {
                const userType = localStorage.getItem("user_type");
                if (!localStorage.getItem("userAuth")) {
                  dispatch(changeShowLogin(true));
                } else {
                  navigate(`${userType.replace(/"/g, "")}/dashboard`);
                }
              }}
              className="text-xs bg-[#0966ED] text-center text-white font-bold py-2 px-4 rounded-md shadow-lg hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out cursor-pointer flex-1"
            >
              Try for Free
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  })}
</div>



//     <>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-5 max-sm:m-6 ">
//         {props?.dataOne?.map((item, index) => {
//           const defaultBanner =
//             "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
//           return (
//             <React.Fragment key={index}>

//               {/* <div className="relative mt-4 border-solid min-h-16 border border-gray-200 hover:shadow-md rounded-lg"> */}
//               <div className="relative mt-4 border-solid min-h-16 border border-gray-200 hover:shadow-md rounded-lg overflow-hidden">
//                 {/* Badge */}
//                 <span
//                   className={`absolute top-2 left-2 text-white text-xs font-bold py-1 px-3 rounded-full shadow-md ${item?.level === "beginner"
//                     ? "bg-green-500"
//                     : item?.level === "intermediate"
//                       ? "bg-yellow-500"
//                       : "bg-red-500"
//                     }`}
//                 >
//                   {item?.level}
//                 </span>

//                 {/* Image */}
//                 <img
//                   // className="w-full max-h-30 rounded-t-lg object-contain"
//                   className="w-full max-h-[12rem] rounded-t-lg object-cover"
//                   src={item?.banner ? item?.banner : defaultBanner}
//                   alt="card-image"
//                 />

//                 <div className="p-3 ">
//                   <h2 className="font-semibold text-sm text-gray-900  ">
//                     {/* <h2 className="font-semibold text-sm text-gray-900 truncate "> */}
//                     {item?.title}
//                   </h2>

//                   <p className="text-sm pt-1 text-gray-600 pr-1">
//                     {/* <p className="text-sm pt-1 text-gray-600 pr-1 truncate"> */}
//                     {truncateText(item?.description, 10)}{" "}
//                     <NavLink
//                       onClick={() => localStorage.setItem("courseId", item?.id)}
//                       to="/forIndividuals"
//                       className="text-blue-500"
//                     >
//                       {/* Learn more <span>&raquo;</span> */}
//                     </NavLink>
//                   </p>
// <p>  Learn more <span>&raquo;</span></p>



//                   <div className="py-2 flex flex-col gap-2">
//                     <div className="flex items-center text-sm text-gray-600">
//                       {/* Views Section */}
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-4 w-4 text-blue-500 mr-1"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                         stroke="currentColor"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
//                         />
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.086.31-.197.613-.332.907M15 12a3 3 0 11-6 0 3 3 0 016 0z"
//                         />
//                       </svg>
//                       <span className="ml-1 ">17k views</span>
//                     </div>

//                     <div className="flex items-center text-sm text-gray-600">
//                       {/* Price Section */}
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-4 w-4 text-green-500 mr-1"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                         stroke="currentColor"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M12 8c1.5 0 3-.667 3-1.5S13.5 5 12 5 9 5.667 9 6.5s1.5 1.5 3 1.5zm0 0c-1.5 0-3 .667-3 1.5s1.5 1.5 3 1.5 3-.667 3-1.5-1.5-1.5-3-1.5zm0 0V5m0 4v10m6 2H6"
//                         />
//                       </svg>
//                       <span className="font-bold text-gray-800">
//                         Price: {(item.price * conversionRate).toFixed(2)}{" "}
//                         {userCurrency}
//                       </span>
//                     </div>
//                   </div>
//                   {/* Button Container */}
//                   <div className="flex justify-between items-center mt-4 space-x-3">
//                     {/* Buy Now Button */}
//                     <div
//                       onClick={() => {
//                         if (localStorage.getItem("userAuth")) {
//                           handlePayment(item?.id);
//                         } else {
//                           dispatch(changeShowLogin(true));
//                         }
//                       }}
                      
//                       className="text-xs bg-[#0966ED] text-center text-white font-bold py-2 px-2 rounded-md shadow-lg hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out cursor-pointer flex-1"
//                     >
//                       Buy Now
//                     </div>

//                     {/* Try for Free Button */}
//                     <div
//                       onClick={() => {
//                         const userType = localStorage.getItem("user_type");
//                         if (!localStorage.getItem("userAuth")) {
//                           dispatch(changeShowLogin(true));
//                         } else {
//                           navigate(`${userType.replace(/"/g, "")}/dashboard`);
//                         }
//                       }}
//                       // bg-blue-500
//                       className="text-xs bg-[#0966ED] text-center text-white font-bold py-2 px-2 rounded-md shadow-lg hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out cursor-pointer flex-1"
//                     >
//                       Try for Free
//                     </div>

//                   </div>
//                 </div>
//               </div>



//             </React.Fragment>
//           );
//         })}
//       </div>
//     </>

  );
};

export default CardOne;
