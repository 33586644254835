import React, { createContext, useState, useEffect, useContext, Suspense } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { RoutePaths } from "./route/RoutePath";
import { Individual } from "./components/pages/Individuals/Individual";
import { ToastContainer } from "react-toastify";
import LinkdinLogin from "./components/pages/login/socialSignupTeacher/LinkdinLogin";
import CategoryCourse from "./components/pages/CategoryCourse";
import apiClient from "./tokenHandle/axiosInstance";
import StudentHomePage from "./studentdashboard/component/StudentHomePage";
import BottomNav from "./components/pages/navigation/BottomNavigation";
import ForIndividuals from "./components/pages/Profile/individual/ForIndividuals";
import EmailVerificationUI from "./components/pages/EmailVerificationUI";
import whatsapp from './assests/images/iconsImg/whtsapp icon.png'
import { GenerateNewPassword } from "./components/pages/password/GenerateNewPassword";

const UserContext = createContext();

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const HeroExplore = React.lazy(() => import("./components/pages/HeroExplore"));
const Account = React.lazy(() => import("./components/pages/Account"));

function App() {
  // Initialize API client
  apiClient();

  const [user, setUser] = useState(null);
  const [getCategoryData, setGetCategoryData] = useState([])
  const [buttonText, setButtonText] = useState("")
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("userAuth");
    if (token) {
      setUser(JSON.parse(token));
    }
  }, []);

  return (
    <>

      <BrowserRouter basename="/">
        <UserContext.Provider value={{ user, setUser, setGetCategoryData, getCategoryData, buttonText, setButtonText, categoryList, setCategoryList }}>
          <AppRoutes />
          <ToastContainer
            limit={1}
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
          />

        </UserContext.Provider>

      </BrowserRouter>
      <ButtonsSideBar />
      <SideChats />


    </>
  );
}

// Separate component to handle routes and conditional rendering
function AppRoutes() {
  const location = useLocation();
  const isStudentRoute = location.pathname.includes('/student');
  const isAdminRoute = location.pathname.includes('/admin');
  const isTeacherRoute = location.pathname.includes('/teacher');
  const { user, setGetCategoryData, getCategoryData } = useContext(UserContext);
  return (
    <>
      {!isStudentRoute && !isAdminRoute && !isTeacherRoute && <BottomNav />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            exact
            path="/"
            name={RoutePaths.home.name}
            element={!user ? <Individual /> : <StudentHomePage />}
          />

          <Route
            exact
            path="/account"
            name="account"
            element={<Account />}
          />

          <Route
            exact
            path="/courses"
            name="Explore"
            element={<HeroExplore />}
          />
          <Route
            exact
            path="/user/password-reset-confirm/:uid/:token"
            name="GenerateNewPassword"
            element={<GenerateNewPassword />}
          />
          <Route
            exact
            path="/courses/:id"
            name="Explore"
            element={<HeroExplore />}
          />
          <Route
            exact
            path="/serch/:id"
            name="Explore"
            element={<HeroExplore />}
          />
          <Route
            exact
            path="/courses"
            name="courses"
            element={<CategoryCourse />}
          />
          <Route
            exact
            path={`/linkedin/:id`}
            name="linkdin"
            element={<LinkdinLogin />}
          />
          <Route
            exact
            path={`/user/account/verify-account/activate/:uid/:id`}
            name="EmailVerification"
            element={< EmailVerificationUI />}
          />
          <Route
            exact
            path="*"
            name="forIndividuals"
            element={<DefaultLayout />}
          />
          <Route
            exact
            path="studentHome"
            name="StudentHomePage"
            element={<StudentHomePage />}
          />
          <Route
            exact
            path="/courses/:id/:title"
            name="forIndividuals"
            element={<ForIndividuals />}
          />
        </Routes>
      </Suspense>



    </>
  );
}


export default App;
export { UserContext };


function SideChats() {
  return (
    <>


      <a href="https://wa.me/918595903939"><svg viewBox="0 0 32 32" class="whatsapp-ico"><path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path></svg></a>

    </>


  );
}

function ButtonsSideBar() {
  return (
    <>
      <div
        className="fixed right-0 bottom-[14%] flex flex-col items-center space-y-16 w-[30px] h-[150px] rounded  "
      >

        <button type="button" class="text-white w-24 h-fit -rotate-90 bg-blue-500 hover:bg-bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"
          onClick={() => {
            window.location.href = "https://wa.me/8595903939";
          }}
        >Chat Now</button>
        <a href="tel:+918595903939" class="text-white no-underline w-24 h-fit -rotate-90 bg-black hover:bg-bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"
        >Call Back</a>

        {/* <a href="tel:+918595903939" class="text-white w-24 h-fit -rotate-90 bg-black hover:bg-gradient-to-bl focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2"

        >Call Back</a> */}

      </div>


    </>
  );
}













