import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RoutePaths } from "../../../route/RoutePath";

const Navigation = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const pathName = location?.pathname?.split("/");

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="">
        <div className="flex max-sm:flex-col sm:flex-col lg:flex-row lg:justify-between  lg:items-center gap-4 pt-1">
          <NavLink
            className={`${
              // activeIndex === 0
              pathName[1] === "forIndividual"
                ? "active no-underline  pb-2 border-b-2 border-solid border-blue-500 cursor-pointer h-7 text-[#000]"
                : "cursor-pointer no-underline  flex-shrink-0 h-7 text-[#000]"
            }`}
            // onClick={() => handleItemClick(0)}
            to={"/forIndividual"}
          >
            For Individuals
          </NavLink>
          <NavLink
            className={`${
              // activeIndex === 1
              pathName[1] === "universities"
                ? "active no-underline text-[#000] pb-2 border-b-2 border-solid border-blue-500 cursor-pointer h-7"
                : "cursor-pointer no-underline text-[#000] flex-shrink-0 h-7"
            }`}
            onClick={() => handleItemClick(1)}
            to={RoutePaths.forUniversities.path}
          >
            For Universities
          </NavLink>
          <NavLink
            className={`${
              // activeIndex === 2
              pathName[1] === "corporate"
                ? "active no-underline text-text-[#000] pb-2 border-b-2 border-solid border-blue-500 cursor-pointer h-7"
                : "cursor-pointer no-underline text-[#000] flex-shrink-0 h-7"
            }`}
            onClick={() => handleItemClick(2)}
            to={RoutePaths?.forCorporates?.path}
          >
            For Corporates
          </NavLink>

          {/* <NavLink to={'/about'}> */}
          <NavLink
            className={`${
              // activeIndex === 2
              pathName[1] === "about"
                ? "active no-underline text-text-[#000] pb-2 border-b-2 border-solid border-blue-500 cursor-pointer h-7"
                : "cursor-pointer no-underline text-[#000] flex-shrink-0 h-7"
            }`}
            onClick={() => handleItemClick(3)}
            to={RoutePaths?.forAbout?.path}
          >
            About Us
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default Navigation;
