import React, { useState, useEffect } from "react";
import Skillgain from "./selectedCourse/Skillgain";
import StudyMaterial from "./selectedCourse/StudyMaterial";
import Debugging from "./selectedCourse/Debugging";
import ManageCertificates from "./selectedCourse/ManageCertificates";
import LearningLab from "./selectedCourse/LearningLab";
import RecommendedCourse from "./selectedCourse/RecommendedCourse";
import pdfUrl from "../../assests/courses/Angular.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Slider from "react-slick";
import { FaCheckCircle } from "react-icons/fa";
import { getCourseById } from "../../store/authApi";
const testimonials = [
  {
    id: 1,
    name: "Olivia H., Front-End Developer",
    testimonial:
      '"This course exceeded my expectations! The modules on routing and forms were particularly helpful, and I can now create dynamic, user-friendly applications."',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Liam T., Software Engineer",
    testimonial:
      '"I had some experience with web development, but this course helped me understand Angular in depth. The explanations were clear, and the focus on best practices was invaluable."',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Mia S., Web Developer",
    testimonial:
      '"I loved this course! The practical examples and real-world applications helped solidify my understanding of Angular. I’m excited to apply what I’ve learned to my projects!"',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    name: "Noah P., Junior Developer",
    testimonial:
      '"This course exceeded my expectations! The modules on routing and forms were particularly helpful, and I can now create dynamic, user-friendly applications."',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 5,
    name: "Ava R., UI/UX Designer",
    testimonial:
      '"I found this course to be extremely well-structured. The focus on component design and performance optimization has significantly improved my development skills!"',
    image: "https://via.placeholder.com/150",
  },
];

const SelectedCourseDetail = () => {
  const [specificCourse, setSpecificCourse] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCourse, setActiveCourse] = useState("About");
  const [courseAbout, setCourseAbout] = useState([]);
  const [courseOutcomes, setCourseOutcomes] = useState([]);
  const [courseSkills, setCourseSkills] = useState([]);
  const courses = [
    { name: "About" },
    { name: "Learning Outcomes" },
    { name: "Modules" },
    { name: "Testimonials" },
    { name: "Course Brief" },
    { name: "Recommendations" },
  ];
  // useEffect(() => {
  //   getCourseById().then((res) => {
  //     if (res) {
  //       try {
  //         // console.log(res.data)
  //         setSpecificCourse(res?.data);

  //         setCourseAbout(res?.data?.about.split("\n"));
  //         setCourseSkills(res?.data?.skills.split(","));
  //         // let rawOutcomes = res?.data?.outcomes;
  //         // rawOutcomes = rawOutcomes.replace(/}\s*{/g, "},{");
  //         // rawOutcomes = `[${rawOutcomes}]`;
  //         // const parsedOutcomes = JSON.parse(rawOutcomes);
  //         //setCourseOutcomes(parsedOutcomes);
  //       } catch (error) {
  //         console.error("Error parsing outcomes: ", error);
  //       }
  //     }
  //   });
  // }, []);

  useEffect(() => {
    getCourseById().then((res) => {
      if (res) {
        try {
          console.log(res.data);
          setSpecificCourse(res?.data);
  
          // Assuming `about` is already an array in the response:
          setCourseAbout(res?.data?.about);
  
          // Assuming `skills` is a comma-separated string:
          setCourseSkills(res?.data?.skills?.split(","));
        } catch (error) {
          console.error("Error handling course data: ", error);
        }
      }
    });
  }, []);
  return (
    <div>
      <div className="relative">

        <div className="flex xl:flex-wrap flex-row overflow-auto gap-4 md:gap-8 my-5">
          {courses.map((course, index) => (
            <div key={index}>
              <p
                className={`cursor-pointer font-medium whitespace-nowrap text-base font-Roboto px-2 py-1 mb-3 rounded-md ${
                  activeIndex === index
                    ? "text-blue-500 bg-[#F4F6FC] border-blue-500"
                    : "text-[#1E1E1E] text-opacity-85"
                }`}
                onClick={() => {
                  setActiveIndex(index);
                  setActiveCourse(course.name);
                }}
              >
                {course.name}
              </p>
              <p
                className={`${
                  activeIndex === index ? "border-blue-500 border-b-4" : "none"
                }`}
              ></p>
            </div>
          ))}

          <hr
            style={{
              backgroundColor: "#e5e7e8",
              minWidth: "90%",
              height: "4px",
              position: "absolute",
              bottom: "0px",
              zIndex: "-1",
            }}
          />
        </div>
      </div>
      <div className="pb-5">

        {activeCourse == "About" && (
          <>
            <div className="pt-4 pb-6">
              <p className="font-Roboto font-semibold text-lg">
                What you'll learn
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 mb-8 gap-4">
    {specificCourse?.about && Array.isArray(specificCourse.about) ? (
      specificCourse.about.map((item, index) => (
        <div
          key={index}
          className="flex items-start gap-4 col-span-1 md:col-span-4"
        >
          <img
            src={process.env.PUBLIC_URL + "/images/student/sign.png"}
            alt="icon"
            className="w-5 h-5"
          />
          <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
            {item}
          </p>
        </div>
      ))
    ) : (
      <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
        No data available
      </p>
    )}
  </div>
          </>
        )}
        {activeCourse == "Learning Outcomes" && (
          <LearningOutcomes courseOutcome={specificCourse} />
        )}

        {activeCourse == "Modules" && (
          <LearningModules modules={specificCourse} />
        ) }
        {activeCourse == "Course Brief" && (
          <div className="border w-full shadow-md rounded-md p-4">
            <h3 className="text-xl font-bold mb-4">PDF Preview</h3>
            <div className="overflow-auto border">
              <iframe
                src={pdfUrl}
                style={{ width: "100%", height: "580px", border: "none" }}
                title="PDF Document"
              />
            </div>
          </div>
        )}
        {activeCourse == "Testimonials" && <TestimonialSlider />}

        <Skillgain courseSkills={specificCourse} />

        <StudyMaterial />
        <Debugging />
        <ManageCertificates />
        <LearningLab />
        <RecommendedCourse />
      </div>
    </div>
  );
};

export default SelectedCourseDetail;

const LearningOutcomes = ({ courseOutcome }) => {
  console.log('courseOutcome', courseOutcome);
  const [courseOutcomes, setCourseOutcomes] = useState([]);

  useEffect(() => {
    // Transform data for consistent structure
    if (Array.isArray(courseOutcome.outcomes)) {
      const transformedOutcomes = courseOutcome.outcomes.map((item, index) => ({
        description: item,
        details: [],
      }));
      setCourseOutcomes(transformedOutcomes);
    }
  }, [courseOutcome.outcomes]);
  console.log("learning out come ", courseOutcomes)

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
        Learning Outcomes
      </h2>
      <h2 className="text-2xl font-semibold my-4 border-b border-gray-300">
        Course Outcomes
      </h2>
      <ul className="space-y-8 mt-4">
        {courseOutcomes.map((outcome, index) => (
          <li key={index} className="flex">
            <div className="pt-1 w-10">
              <FaCheckCircle className="text-green-500 w-6 h-6 text-3xl mr-4" />
            </div>
            <div>
              <h3 className="text-xl font-semibold  text-gray-800 mb-2">
              {outcome.description.split(':')[0]} :<p className=" text-sm font-normal">{outcome.description.split(':')[1]}</p>
              </h3>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
// const LearningModules = ({modules}) => {
//   const [courseModule, setCourseModule] = useState([]);
//   useEffect(() => {
//       setCourseModule(modules);
//   }, [modules]);
//   console.log("course module", courseModule)
//   return (
//     <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//     <h2 className="text-3xl font-semibold text-center mb-8">Learning Modules</h2>
//     <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
//       { courseModule === undefined && courseModule?.modules && courseModule?.modules?.map((module, index) => (
//         <div
//           key={index}
//           className="bg-white p-6 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-lg"
//         >
//           <h3 className="text-xl font-bold text-gray-800 mb-4">
//             {index + 1}. {module.module}
//           </h3>
//           <ul className="list-disc list-inside text-gray-700 space-y-2">
//             { module === undefined && module?.lecture?.map((lecture, i) => (
//               <li key={i} className="text-sm">{lecture.name}</li>
//             ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//   </div>
//   );
// };



const LearningModules = ({ modules }) => {
  const [courseModule, setCourseModule] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    // Simulate data fetching or waiting for `modules` prop
    // if (Array.isArray(modules)) {
      setCourseModule(modules);
      setIsLoading(false); // Stop loading when data is set
    // } else {
      // setIsLoading(true); // Keep loading if no data
    // }
  }, [modules]);

  console.log("module", courseModule)

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-semibold text-center mb-8">Learning Modules</h2>
      {isLoading ? (
        // Show a loading spinner or message while loading
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
      ) : (
        // Render the modules once loading is complete
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {courseModule?.modules?.map((module, index) => (
            <div
              key={index}
              className="bg-white p-6 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-lg"
            >
              <h3 className="text-xl font-bold text-gray-800 mb-4">
                {index + 1}. {module.module}
              </h3>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                {module?.lecture &&
                  module?.lecture?.map((lecture, i) => (
                    <li key={i} className="text-sm">{lecture}</li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 2, // Show two cards at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For mobile view
        settings: {
          slidesToShow: 1, // Show one card on smaller screens
        },
      },
    ],
  };

  return (
    <div className="w-4/5 mx-auto">
      <h2 className="text-3xl font-semibold text-center mb-6">Testimonials</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <TestimonialCard
            key={testimonial.id}
            name={testimonial.name}
            testimonial={testimonial.testimonial}
            image={testimonial.image}
          />
        ))}
      </Slider>
    </div>
  );
};

const TestimonialCard = ({ name, testimonial, image }) => {
  return (
    <div className="p-6 border border-gray-200 rounded-lg shadow-md text-center bg-white hover:shadow-lg transition-shadow duration-300 mx-3">
      <img
        src={image}
        alt={name}
        className="w-24 h-24 mx-auto rounded-full object-cover"
      />
      <h4 className="mt-4 text-xl font-semibold text-gray-800">{name}</h4>
      <p className="mt-2 text-gray-600 italic">{testimonial}</p>
    </div>
  );
};
