import React from "react";
import batchstart from "../../assests/images/stats/batchstart.png";
import chat from "../../assests/images/stats/chat.png";
import career from "../../assests/images/stats/carrer.png";
import hiring from "../../assests/images/stats/hiring.png";
import duration from "../../assests/images/stats/duration.png";
import internship from "../../assests/images/stats/internshp.png";
import { NavLink, useNavigate } from "react-router-dom";

const StatsSection = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/courses', { state: { path: 'free' } });
  }
  return (
<div className="bg-gray-100">
  {/* Stats Section */}
  <div className="flex flex-col lg:flex-row justify-around items-center px-4 py-6 bg-white shadow-md space-y-4 sm:space-y-0 sm:justify-center md:justify-around">
    {/* Header Section */}
    <div className="flex flex-col justify-between items-center px-6 py-4 bg-white shadow-md w-full sm:w-auto">
      <div className="text-yellow-500 flex gap-2 md:flex-col md:gap-0 font-semibold text-center">
        <div>Courses</div>
        <div>Features</div>
      </div>
      <NavLink 
        to={{
          pathname: "/courses",
          search: "?query=free",
        }} 
        className="bg-yellow-500 text-white text-xl px-4 py-2 rounded font-medium  no-underline mt-4 sm:mt-2"
      >
        Explore All
      </NavLink>
    </div>

    {/* Stat Items */}
    <div className="flex flex-wrap justify-center items-center w-full lg:w-auto lg:flex-row lg:space-x-4">
      {[
        { headCounts: '231+', label: "Courses", src: batchstart },
        { headCounts: '8000+', label: "Success Students", src: chat },
        { headCounts: '250+', label: "Expert Tutor", src: career },
        { headCounts: '24X7', label: "Support", src: hiring },
        { headCounts: '120+', label: "Carrier Coach", src: duration },
        { headCounts: '6 Months', label: "Internship", src: internship },
      ].map((item, index) => (
        <div
          key={index}
          className="text-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-auto p-2"
        >
          <div className="rounded-full p-1 w-16 h-16 mx-auto bg-gradient-to-r from-blue-500 via-pink-500 to-cyan-500">
            <div className="rounded-full w-full h-full bg-white p-2">
              <img
                className="w-full h-full rounded-full"
                src={item.src}
                alt={item.label}
              />
            </div>
          </div>
          <div className="text-xl font-bold text-black mt-2">{item.headCounts}</div>
          <div className="text-gray-600">{item.label}</div>
        </div>
      ))}
    </div>
  </div>
</div>
  
  );
};

export default StatsSection;
